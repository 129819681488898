﻿import React, { useCallback, useEffect, useRef, useMemo, Component } from 'react';
import { useState } from "react";
import { useAuthFetch } from '../useAuthFetch';
import { getApiUrl } from '../authConfig';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CreateAssetDialog } from '../CreateAssetDialog';
import { InspectionPreview } from '../InspectionPreview';
import moment from 'moment';
import { toJS } from 'mobx';
import jsZip from 'jszip';
import ReactDomServer from 'react-dom/server';
import { useMsal } from '@azure/msal-react';

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import "../assets/css/formItems.css";

import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';

import {
    ColDef,
    RowSelectionOptions,
    SideBarDef,
    ValueFormatterParams,
} from "ag-grid-community";

import { assetDataInspectionlatestByBuilding, assetDataMoveBuilding } from "../hooks/Asset";
import { requestBuildingData } from "../hooks/Building";
import { requestInspectionDataLast } from "../hooks/Inspection";
import { getProductGroupByProductFamily } from "../hooks/Product";
import { requestUserGridConfigsUpdateDefault, requestUserGridConfigs, deleteUserGridConfig } from "../hooks/UsersGridConfigs";
import { requestReports } from "../hooks/Reports";
import { createPDF, createPDFInspection, createPDFRoi } from '../hooks/CreatePDF';
import { getROILatestData } from "../hooks/Roi";

import { AssetObject, UserGridConfigObject } from "../common/types";

import { RoiMetering } from '../components/RoiMetering';

import html2canvas from 'html2canvas-pro';
import { saveAs } from 'file-saver';

import Modal from 'react-bootstrap/Modal';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../assets/css/roiPage.css";

export function AssetsPage() {
    const gridRef = useRef<AgGridReact>(null);
    const navigate = useNavigate();
    const authFetch = useAuthFetch();
    const params = useParams();
    const interval = React.useRef(null);

    const { accounts } = useMsal();

    const [ assetSuccess, setAssetSuccess] = useState<string | null>(null);
    const [ loading, setLoading ] = useState(true); 
    const [ buildingData, setBuildingData ] = useState([]);
    const [ assetData, setAssetData ] = useState([]);
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ rowChecked, setRowChecked] = useState([]);
    const [ productDropdownData, setProductDropdownData] = useState([]);
    const [ modalGridConfigOpen, setModalGridConfigOpen] = useState(false); 

    const [ rowActionPending, setRowActionPending] = useState(false);
    const [ validated, setValidated] = useState(false);
    const [ gridConfigDescription, setGridConfigDescription] = useState(null);
    const [ userGridConfigsData, setUserGridConfigsData] = useState<UserGridConfigObject[]>([]);

    const [ modalQAChangeOpen, setModalQAChangeOpen] = useState(false); 
    const [ modal, setModal] = useState({ show: false, title: "", newValue: "", data: [] });

    const [ inspectionPreviewOpen, setInspectionPreviewOpen] = useState(false);
    const [ inspectionAssetItemData, setInspectionAssetItemData] = useState([]);
    const [inspectionData, setInspectionData] = useState(null);

    const [assetMoveOpen, setAssetMoveOpen] = useState(false);
    const [assetBuildingMove, setAssetBuildingMove] = useState("");

    const [buildingSelect, setBuildingSelect] = useState([]);
    const [progressNow, setProgressNow] = useState(0);

    const [selectedUserGridConfig, setSelectedUserGridConfig] = useState<UserGridConfigObject>(null);
    

    let buildingId = params.buildingId || "";

    if (buildingId == "" && buildingData != null && buildingData?.length != 0) {
        buildingId = buildingData[0].id;
    }

    const requestData = async ()=> {
        setLoading(true);
        await assetDataInspectionlatestByBuilding(authFetch, buildingId).then(res => { return res; }).then((data) => { setAssetData(data); });
        setLoading(false);
    }

    const requestUserConfigData = async (initialLoad: boolean) => {
            await requestUserGridConfigs(authFetch, "asset").then(res => { return res; }).then((data) => {
                let configDefault = data.find(it => it.isDefault == true);
                setUserGridConfigsData(data);
                if (initialLoad == true && configDefault != undefined) {
                    gridRef?.current!.api.applyColumnState({
                        state: configDefault.columnSpec,
                        applyOrder: true,
                    });
                }
            });
    }

    const requestConfigsUpdateDefault = async (gridConfigId: string, isDefault) => {
        selectedUserGridConfig.isDefault = isDefault.target.checked;
        await requestUserGridConfigsUpdateDefault(authFetch, gridConfigId, isDefault.target.checked).then(res => { return res; }).then((data) => { setUserGridConfigsData(data); });
        requestUserConfigData(false);
    }

    const deleteUserConfigData = async (gridConfigId: string) => {
        await deleteUserGridConfig(authFetch, gridConfigId);
        requestUserConfigData(false);
    }

    const requestInitialData = async () => {
        setLoading(true);
        progressBarStart();
        await requestBuildingData(authFetch, "slim").then(res => { return res; }).then((data) => {
            setBuildingData(data);
            if (buildingId == "" && data.length != 0) {
                buildingId = data[0].id;
                setBuildingSelect(data[0].name);
            } else {
                setBuildingSelect(data.find(it => it.id == buildingId).name);
            }
        });
        await assetDataInspectionlatestByBuilding(authFetch, buildingId).then(res => { return res; }).then((data) => { setAssetData(data); });
        requestUserConfigData(true);
        progressBarEnd();
        setLoading(false);
    }

    useEffect(() => { requestInitialData()}, [params.buildingId]);

    const handleClose = () => { setInspectionPreviewOpen(false); };
    const handleDialogOpen = () => { setDialogOpen(true); }
    const onBtnExport = useCallback(() => { gridRef.current!.api.exportDataAsCsv(); }, []);
    const onInspectionPdfExport = () => { downloadInspectionPdf(rowChecked); }
    const onROIPdfExport = () => { downloadROIPdf(rowChecked); }


    const handleDialogSubmit = (status: string) => {
        if(status != "cancel") {
            requestData(); 
        }
        setDialogOpen(false);
    }

    const inspectionDataFunction = async (zip: jsZip, item, assetItem) => {
        await requestInspectionDataLast(authFetch, item.lastInspectionId).then(res => { return res; }).then((rInspectionData) => {
            const htmlString = ReactDomServer.renderToStaticMarkup(<InspectionPreview assetData={item} inspectionData={rInspectionData} pdfDownload="true" />);
            const iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.contentWindow.document.write(htmlString);

            html2canvas(iframe.contentWindow.document.body, { useCORS: true, allowTaint: true, scrollY: 0, scale: 5 }).then(canvas => {
                if (parseInt(canvas.getAttribute("height")) > 1000) {
                    const jsPdf = createPDF(canvas);
                    if (assetItem.length === 1) {
                        jsPdf.save("inspection-" + item.id + ".pdf");
                        setRowActionPending(false);
                    } else {
                        zip.file(`inspection- ${item.id}.pdf`, jsPdf.output('blob'));
                    }
                }
                iframe.remove();
                canvas.remove();
            });
        });
    }

    const roiDataFunction = async (zip: jsZip, item: AssetObject, assetItem: AssetObject[]) => {
        await getROILatestData(authFetch, item.id).then(res => { return res; }).then((roiData) => {
            if (roiData != undefined) {
                const htmlString = ReactDomServer.renderToStaticMarkup(<RoiMetering roiData={roiData} />);
                const iframe = document.createElement("iframe");
                iframe.style.width = '0';
                iframe.style.height = '0';
                document.body.appendChild(iframe);
                iframe.contentWindow.document.write(htmlString);

                html2canvas(iframe.contentWindow.document.body, { useCORS: true, allowTaint: true, scrollY: 0, scale: 5 }).then(canvas => {
                    if (parseInt(canvas.getAttribute("height")) > 1000) {
                        setTimeout(function () {
                            const jsPdf = createPDFRoi(roiData, iframe);
                            if (assetItem.length === 1) {
                                jsPdf.save("ROI_" + item.building.address + "_" + item.model + "_" + item.serialNumber + "_" + roiData.inspection.completionDate.substring(0, roiData.inspection.completionDate.indexOf("T")) + "_rev" + roiData.version + ".pdf");
                                setRowActionPending(false);
                            } else {
                                zip.file(`ROI_${item.building.address}_${item.model}_${item.serialNumber}_${roiData.inspection.completionDate.substring(0, roiData.inspection.completionDate.indexOf("T"))}_rev${roiData.version}.pdf`, jsPdf.output('blob'));
                            }
                        }, 5000);
                    }
                    //iframe.remove();
                    //canvas.remove();
                });
            }
        });
    }

    const getInspectionData = async (zip: jsZip, item, assetItem) => {
        await requestInspectionDataLast(authFetch, item.lastInspectionId).then(res => { return res; }).then((rInspectionData) => {
            createPDFInspection(zip, assetItem, item, rInspectionData);
        });
    }

    const downloadInspectionPdf = async (assetItem) => {
        setRowActionPending(true);
        var zip = new jsZip();

        const promises = [];
        assetItem.map((item) => {
            if (item.lastInspectionId !=null) {
                promises.push(getInspectionData(zip, item, assetItem));
            }
        });
        if (promises.length > 0) {
            await Promise.all(promises).then((x) => {
                if ((assetItem.length > 1)) {
                    setTimeout(() => {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, `inspectionReport.zip`);
                            setRowActionPending(false);
                        });
                    }, 8000);
                } else {
                    setRowActionPending(false);
                }
            });
        } else {
            setRowActionPending(false);
        }

    };

    const downloadROIPdf = async (assetItem: AssetObject[]) => {
        setRowActionPending(true);
        var zip = new jsZip();

        const promises = [];
        assetItem.map((item: AssetObject) => {
            if (item.assetType === "Electricity") {
                promises.push(roiDataFunction(zip, item, assetItem));
            }
        });
        if (promises.length > 0) {
            await Promise.all(promises).then((x) => {
                if ((assetItem.length > 1)) {
                    setTimeout(() => {
                        zip.generateAsync({ type: 'blob' }).then(function (content) {
                            saveAs(content, `ROI.zip`);
                            setRowActionPending(false);
                        });
                    }, 8000);
                } else {
                    setRowActionPending(false);
                }
            });
        } else {
            setRowActionPending(false);
        }
    };

    const CustomInspectionLinkComponent = (props) => {
        if (props != null && props.data != null && props.data.lastInspectionId != null) {
            return (
                /*<Button type="button" className="btn btn-primary btn-sm" onClick={(e) => {
                    requestInspectionDataLast(authFetch, props.data.lastInspectionId).then(res => { return res; }).then((rInspectionData) => {
                        setInspectionData(rInspectionData);
                        e.preventDefault();
                        setInspectionPreviewOpen(true);
                        setInspectionAssetItemData(props.data);
                    });
                }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Button>*/
                <Link target="_blank" to={{ pathname: '/inspection/' + props.data.id + '/', }}>
                    <Button type="button" className="badge rounded-pill grid-badge info">{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Button>
                </Link>
                /*<Button type="button" target="_blank" className="btn btn-primary btn-sm">{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Button>*/
                /*<Link target="_blank" to={{ pathname: '/inspection/' + props.data.id, }}>{moment(props.data.lastInspectionDate).format('YYYY-MM-DD')}</Link>*/
            )
        }
    };

    const handleChecked = async (assetId) => {
        let prev = rowChecked;
        let itemIndex = prev.indexOf(assetId);
        if (itemIndex !== -1) {
            prev.splice(itemIndex, 1);
        } else {
            prev.push(assetId);
        }
        setRowChecked([...prev]);
    }

    const qaChangeConfirm = async (params) => {
        if (params.newValue != "Cancel") {
            setModalQAChangeOpen(true);
        }
    }

    const cellClickEvent = async (params) => {

        let columnSelected = params.colDef.field.split('.')[0];
        
        if (params.newValue === "Cancel") {
            cancelQAChange(params);
            params.api.refreshCells({ columns: [columnSelected + ".completionDate"] });
        } else {
            const date = new Date();
            let statusType = 0;
            if (params.newValue === "Pass") {
                statusType = 1;
            } else if (params.newValue === "Fail"){
                statusType = 2;
            }

            const jsonArray = '{"qaStatus": "' + statusType +'", "completionDate": "' + date.toISOString() + '", "updatedBy": "' + accounts[0]?.name + '"}'
            let data = params.data;
            if (columnSelected === "techReview") {
                data.techReview = JSON.parse(jsonArray);
                params.data.qaApproval.completionDate = date;
            } else if (columnSelected === "qaReview") {
                data.qaReview = JSON.parse(jsonArray);
                params.data.qaReview.completionDate = date;
            } else if (columnSelected === "qaApproval") {
                data.qaApproval = JSON.parse(jsonArray);
                params.data.qaApproval.completionDate = date;
            }

            params.api.refreshCells({ columns: [columnSelected + ".completionDate"] });

            let url = getApiUrl("assets");
            url = `${url}/${params.data.id}`
     
             let fetchResponse = await authFetch("PUT", url, { ...data, buildingId: buildingId })
             if (fetchResponse.status != 409) {
                 console.log("UPDATED!");
             } else {
                 console.log("ERROR");
             }
        }
        closeQAModal();
    }

   function formatterCell(params: ValueFormatterParams) {

        let columnSelected = params.colDef.field.split('.')[0];
        let returnValue = "";
        if (params.data == null) {
            returnValue = params.value;
        } else {
            if (params.data != null && params.data.qaApproval != null && params.data.qaApproval.qaStatus != '0' && columnSelected === "qaApproval") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            } else if (params.data != null && params.data.qaReview != null && params.data.qaReview.qaStatus != '0' && columnSelected === "qaReview") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            } else if (params.data != null && params.data.techReview != null && params.data.techReview.qaStatus != '0' && columnSelected === "techReview") {
                returnValue = params.value == null ? "" : moment(params.value).format('YYYY-MM-DD');
            }
        }
        return returnValue;
    }

    const gridAssetLinkComponent = (props) => {
        if (props.data === undefined) {

        } else {
            return (
                <div style={{ marginLeft: "-10px" }}>
                    <Button type="button" className="badge rounded-pill grid-badge info" onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`/asset/${props.data.id}`)
                    }}><i className="fa fa-external-link" />
                    </Button>
                </div>
            )
        }
    };

    function qaStatusmapping(qaStatusValue) {
        if (qaStatusValue === 0) {
            return "Incomplete";
        } else if(qaStatusValue === 1) {
            return "Pass";
        } else if (qaStatusValue === 2) {
            return "Fail";
        }

    }

    function dateFormat(dateValue: string) {
        var dateSetting = moment("01.01.1980 23:59:00", "DD.MM.YYYY HH.mm.ss");
        if ((dateValue == null) || (moment(dateValue).isBefore(dateSetting))) {
            return <span>&nbsp;</span>;
        } else {
            return moment(dateValue).format('yyyy-MM-DD HH:mm');
        }
    }

    function passButtonHoverText(qaApproval) {
        return "Approved By : " + qaApproval.updatedBy + " " + dateFormat(qaApproval.completionDate);
    }

    const qaStatusComponent2 = (props) => {
        let statusMappedValue = "Pass";

        if (props.data === undefined || props.data.assetType != "Electricity") {

        } else {
            if (qaStatusmapping(props.data.techReview.qaStatus) != "Pass") {
                statusMappedValue = "SR Tech: " + qaStatusmapping(props.data.techReview.qaStatus);
            } else if (qaStatusmapping(props.data.qaReview.qaStatus) != "Pass") {
                statusMappedValue = "QA Controller: " + qaStatusmapping(props.data.qaReview.qaStatus);
            } else if (qaStatusmapping(props.data.qaApproval.qaStatus) != "Pass") {
                statusMappedValue = "QA Manager: " + qaStatusmapping(props.data.qaApproval.qaStatus);
            }

            if (statusMappedValue.includes("Pass")) {
                return (
                    <div style={{ marginLeft: "-10px" }}>
                        <Link target="_blank" to={{ pathname: '/roiview/' + props.data.id + '/', }}>
                            <Button title={passButtonHoverText(props.data.qaApproval)} type="button" style={{ width: "100%", fontSize: "0.9em" }} className="badge rounded-pill grid-badge success">
                                {statusMappedValue}
                            </Button>
                        </Link>
                    </div>
                )
            } else if (statusMappedValue.includes("Fail")) {
                return (
                    <div style={{ marginLeft: "-10px" }}>
                        <Link target="_blank" to={{ pathname: '/roiview/' + props.data.id + '/', }}>
                            <Button type="button" style={{ width: "100%", fontSize: "0.9em" }} className="badge rounded-pill grid-badge danger">
                                {statusMappedValue}
                            </Button>
                        </Link>
                    </div>
                )
            } else {
                return (
                    <div style={{ marginLeft: "-10px" }}>
                        <Link target="_blank" to={{ pathname: '/roiview/' + props.data.id + '/', }}>
                            <Button type="button" style={{ width: "100%", fontSize: "0.9em" }} className="badge rounded-pill grid-badge warning">
                                {statusMappedValue}
                            </Button>
                        </Link>
                    </div>
                )
            }
        }
    }

    const qaStatusComponent = (props) => {
        const cellStatus = props.statusType;

        let statusMappedValue = "";

        if (cellStatus === "techReview") {
            statusMappedValue = qaStatusmapping(props.data.techReview.qaStatus);
        } else if (cellStatus === "qaReview") {
            statusMappedValue = qaStatusmapping(props.data.qaReview.qaStatus);
        } else if (cellStatus === "qaApproval") {
            statusMappedValue = qaStatusmapping(props.data.qaApproval.qaStatus);
        }

        if (statusMappedValue === "Pass") {
            return (
                <div style={{ marginLeft: "-10px" }}>
                    <Button type="button" style={{ width: "100%", fontSize:"0.9em" }} variant="success" className="btn btn-primary btn-sm" onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`/roiview/${props.data.id}`)
                    }}>{statusMappedValue}</Button>
                </div>
            )
        } else if (statusMappedValue === "Fail") {
            return (
                <div style={{ marginLeft: "-10px" }}>
                    <Button type="button" style={{ width: "100%", fontSize:"0.9em" }} variant="danger" className="btn btn-primary btn-sm" onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`/roiview/${props.data.id}`)
                    }}>{statusMappedValue}</Button>
                </div>
            )
        } else {
            return (
                <div style={{ marginLeft: "-10px" }}>
                    <Button type="button" style={{ width: "100%", fontSize:"0.9em" }} variant="secondary" className="btn btn-primary btn-sm" onClick={(ev) => {
                        ev.stopPropagation();
                        navigate(`/roiview/${props.data.id}`)
                    }}>{statusMappedValue}</Button>
                </div>
            )
        }
    };

    const rowSelection = useMemo<RowSelectionOptions>(
        () => ({
            mode: 'multiRow',
            hideDisabledCheckboxes: true,
        }),
        []
    );

    function InspectionValueGetter(params) {
        if (params?.data?.lastInspectionDate === undefined) {
            var defaultDate = "1970-01-01T15:50:41.670715Z";
            return defaultDate;
        } else {
            return params.data.lastInspectionDate;
        }
    }

   const [colDefs, setColDefs] = useState([
       { headerName: "", suppressHeaderMenuButton: true, field: "id", maxWidth: 50, cellRenderer: gridAssetLinkComponent },
       { field: "assetType", maxWidth: 100, enableRowGroup: true },
       { field: "manufacturer", enableRowGroup: true },
       { field: "model", enableRowGroup: true },
       { headerName: "Serial Num", field: "serialNumber", minWidth: 150 },
       { headerName: "QA Status", field: "techReview.qaStatus", maxWidth: 175, cellRenderer: qaStatusComponent2, cellRendererParams: {
               statusType: "techReview"
           }
       },
       { headerName: "Inspection", minWidth: 125, maxWidth: 125, cellRenderer: CustomInspectionLinkComponent, valueGetter: InspectionValueGetter },
       { field: "status", minWidth: 200, enableRowGroup: true },
       {
           headerName: "Date", field: "creationDate", maxWidth: 110, sort: "desc",
           cellRenderer: (data) => {
               return moment(data.value).format('YYYY-MM-DD');
           }
       },
       { headerName: "Prod Code", field: "productCode", minWidth: 200, enableRowGroup: true },


       { headerName: "Comm Serial Num", field: "commSerialNumber" },
       { headerName: "Commissioning", field: "commissioning" }


       /* {
           headerName: "QA Review", field: "qaReview.qaStatus", maxWidth: 125, cellRenderer: qaStatusComponent, cellRendererParams: {
               statusType: "qaReview"
           }
        },
        {
            headerName: "QA Approval", field: "qaApproval.qaStatus", maxWidth: 130, cellRenderer: qaStatusComponent, cellRendererParams: {
                statusType: "qaApproval"
            }
        },*/
       /* {
            headerName: "Tech Review", field: "techReview.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
            cellClassRules: {
                'cellPass': (params) => { return params.data.techReview.qaStatus == '1';},
                'cellFail': (params) => { return params.data.techReview.qaStatus == '2';},
            },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
            onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "techReview.updatedBy",
            cellEditorParams: {
                values: ['Cancel', 'Pass', 'Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { openQAModal(e); }
        },
        {
            headerName: "QA Review", field: "qaReview.completionDate", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
                    cellClassRules: {
                        'cellPass': (params) => { return params.data.qaReview.qaStatus == '1';},
                        'cellFail': (params) => { return params.data.qaReview.qaStatus == '2';},
                    },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
                    onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "qaReview.updatedBy",
            cellEditorParams: {
                values: ['Cancel','Pass','Fail',],
            } as IRichCellEditorParams,
            onCellValueChanged: (e) => { openQAModal(e); }
        }, {
            headerName: "QA Approval", fieldqaApproval.completionDate: "", stopEditingwhenGridLosesFocus: true,
            valueFormatter: formatterCell,
            cellClassRules: {
                'cellPass': (params) => { return params.data.qaApproval.qaStatus == '1';},
                'cellFail': (params) => { return params.data.qaApproval.qaStatus == '2';},
            },
            maxWidth: 150, minWidth: 150, cellEditor: 'agSelectCellEditor',
            onCellClicked: params => { params.event.preventDefault() },
            tooltipField: "qaApproval.updatedBy",
            cellEditorParams: {
                values: ['Cancel', 'Pass', 'Fail',],
            } as IRichCellEditorParams,
            //onClick={openAudi}
            onCellValueChanged: (e) => { openQAModal(e); }
            //onCellValueChanged: (e) => { qaChangeConfirm(e); cellClickEvent(e); }
        },*/

    ]);

   const defaultColDef = useMemo<ColDef>(() => {
        return {
           // flex: 1,
            //minWidth: 100,
            //editable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        };
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current!.api.setGridOption(
            "quickFilterText",
            (document.getElementById("filter-text-box") as HTMLInputElement).value,
        );
    }, []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' }
            ]
        };
    }, []);

    const handleInspectionPreviewDialogClose = (status: string) => {
        if (status != "cancel") {
            requestData();
        }
    }

  const sideBar = useMemo<
        SideBarDef | string | string[] | boolean | null
    >(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
            defaultToolPanel: "",
        };
    }, []);

    const undo = useCallback(() => {
        gridRef.current!.api.undoCellEditing();
    }, []);

    const openQAModal = (e) => {
        if (e.newValue != "Cancel") {
            let columnSelected = e.colDef.field.split('.')[0];
            setModal({ show: true, title: columnSelected, newValue: e.newValue, data: e });
        } else {
            cancelQAChange(e);
        }
    };

    const closeQAModal = () => {
        setModal({ show: false, title: "", newValue: "", data: null });
    };

    const setModalBodyText = (newValue) => {
        return (
            newValue == "Fail" ?
                <span style={{ backgroundColor: "red", color: "white", fontWeight: "bold", padding: "4px" }}>{newValue}</span>
                :
                <span style={{ backgroundColor: "green", color: "white", fontWeight: "bold", padding: "4px" }}>{newValue}</span>
        )
    };

    function setModalTitle(titleValue) {
        let returnValue = "";
        if (titleValue === "techReview") {
            returnValue = "Tech Review";
        } else if (titleValue === "qaReview") {
            returnValue = "QA Review";
        } else if (titleValue === "qaApproval") {
            returnValue = "QA Approval";
        }
        return returnValue;
    }

    const cancelQAChange = (e) => {
        e.newValue = e.oldValue;
        let columnSelected = e.colDef.field.split('.')[0];
        if (columnSelected === "techReview") {
            e.data.techReview.completionDate = e.oldValue;
        } else if (columnSelected === "qaReview") {
            e.data.qaReview.completionDate = e.oldValue;
        } else if (columnSelected === "qaApproval") {
            e.data.qaApproval.completionDate = e.oldValue;
        }
        e.api.refreshCells();
    };

    const assetAdded = (value: string) => { toast(value); };

    useEffect(() => { assetAdded(assetSuccess); }, [assetSuccess]);

    const createAsstModalData = async () => {
        await getProductGroupByProductFamily(authFetch).then(res => { return res; }).then((data) => { setProductDropdownData(data); });
    }

    useEffect(() => { createAsstModalData() }, []);

    const onCsvExport = useCallback(() => {
        gridRef.current!.api.exportDataAsCsv();
    }, []);

    const onExcelExport = useCallback(() => {
        gridRef.current!.api.exportDataAsExcel();
    }, []);

    const handleSubmit = async (event) => {
        let userGridConfig = {} as UserGridConfigObject;
        userGridConfig.description = gridConfigDescription;
        userGridConfig.gridName = "asset";
        userGridConfig.columnSpec = gridRef.current!.api.getColumnState();

        let data = toJS(userGridConfig);
        let fetchResponse = await authFetch("POST", getApiUrl("usergridconfigs"), { ...data });
        if (fetchResponse.status === 406) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else if (fetchResponse.status != 409) {
            console.log("Added");
            setModalGridConfigOpen(false);
            requestUserConfigData(false);
        } else {
            console.log("Error: " + fetchResponse.status);
        }
    }

    const addPageSetting = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            handleSubmit(false);
            event.preventDefault();
        }
    }

    const userConfigChange = (event) => {
        let userGridConfigSelected = userGridConfigsData.find(x => x.id === event.target.value);
        setSelectedUserGridConfig(userGridConfigSelected);
        gridRef.current!.api.applyColumnState({
            state: userGridConfigSelected.columnSpec,
            applyOrder: true,
        });
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a href="" ref={ref} style={{ fontWeight: "normal" }} onClick={(e) => { e.preventDefault(); onClick(e);}}>{children}</a>
    ));

    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [value, setValue] = useState('');

            return (
                <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                    <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter((child) =>!value || child.props.children.toLowerCase().includes(value.toLowerCase()),)}
                    </ul>
                </div>
            );
        },
    );

    function progressBarStart() {
        if (interval.current != null) { clearInterval(interval.current); }

        interval.current = setInterval(() => {
            if (progressNow >= 100) {
                clearInterval(interval.current);
            } else {
                setProgressNow((prevCount) => prevCount + 1);
            }
        }, 75);
    }

    function progressBarEnd() {
        clearInterval(interval.current);
        setProgressNow(0);
        interval.current = null;
    }

    const ReportDownload = async (buildingId: string, assetIds: string) => {
        setRowActionPending(true);
        await requestReports(authFetch, buildingId, assetIds).then(res => { setRowActionPending(false); return res; }).catch((error) => { console.log(error); setRowActionPending(false) });
    }
    function downloadReport(buildingId: string, assetIds: string) {
        ReportDownload(buildingId, assetIds);
    }

    const moveAssetFunction = async (url: string, item: any, assetBuildingMove: string) => {
        await assetDataMoveBuilding(authFetch, url, item, assetBuildingMove).then(res => { return res; }).then((data) => { setRowActionPending(false); requestData(); });
    }

    const moveAsset = async () => {
        rowChecked.map((item) => {
            let url = getApiUrl("assets");
            url = `${url}/${item.id}`;
            item.buildingId = assetBuildingMove;

            moveAssetFunction(url, item, assetBuildingMove);
        });
    }

    return (
        <>
        <ToastContainer />
            <div>
                {buildingSelect.length <= 0 ? "" : <Button variant="primary" className="rounded-circle modalAddButton" onClick={handleDialogOpen}>+</Button>}

                <div className="titleRow">
                    <div style={{ flexGrow: '1' }}>
                        <div style={{ width: "95px", float: "left" }}>Assets:</div>
                        <Dropdown style={{ width: "775px" }} >
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                {buildingSelect.length <= 0 ? "Select a Building" : buildingSelect}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu} style={{ height: "500px", overflowY: "auto" }} >
                                {buildingData?.map((item) => (
                                    <Dropdown.Item key={item.id} onClick={() => { setRowChecked([]); navigate(`/assets/${item.id}`); setBuildingSelect(item.name); }}>
                                        {item.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <i className="fa-solid fa-gear clickable hoverable" onClick={() => { setModalGridConfigOpen(true) }}></i>
                </div>

                <div className="row mb-2">
                    <div className="col col-8 col-lg-3 col-sm-6">
                        <input type="text" id="filter-text-box" className="form-control" placeholder="Filter..." onInput={onFilterTextBoxChanged} />
                    </div>
                    <div style={{ float: "left", width: "8%" }} >
                        <i className="fa-solid fa-file-excel fa-2x me-2 clickable hoverable" onClick={onExcelExport} style={{ lineHeight: '1.25em' }}></i>
                        <i className="fa-solid fa-file-csv fa-2x clickable hoverable" onClick={onCsvExport}></i>
                    </div>
                    {rowChecked && rowChecked.length > 0 &&
                    <div className="dropdown-container check-action-container" style={{ float: "left", width: "190px" }}>
                            {rowActionPending === true ?
                                <>
                                    <Spinner animation="grow" variant="primary" style={{ float: "left", height: "15px", width: "15px" }} />
                                    <div style={{ color: "blue", width: "235px", lineHeight: "18px", fontSize: "18px" }}>Row Action Pending...</div>
                                </>
                                :
                                <DropdownButton className="pdfButton" variant="outline-secondary" title="Row Action">
                                    <FontAwesomeIcon icon={faDownload} className="me-3" style={{ float: "left", marginLeft: "10px", marginTop: "5px" }} />
                                    <Dropdown.Item style={{ clear: "none" }} onClick={() => onInspectionPdfExport()}>Inspection PDF</Dropdown.Item>
                                    <FontAwesomeIcon icon={faFileExcel} className="me-3" style={{ float: "left", marginLeft: "10px", marginTop: "5px" }} />
                                    <Dropdown.Item style={{ clear: "none" }} onClick={() => downloadReport(buildingId, "")}>SMC Report</Dropdown.Item>
                                    <FontAwesomeIcon icon={faRepeat} className="me-3" style={{ float: "left", marginLeft: "10px", marginTop: "5px" }} />
                                    <Dropdown.Item style={{ clear: "none" }} onClick={() => setAssetMoveOpen(true)}>Move Assets</Dropdown.Item>
                                    <FontAwesomeIcon icon={faDownload} className="me-3" style={{ float: "left", marginLeft: "10px", marginTop: "5px" }} />
                                    <Dropdown.Item style={{ clear: "none" }} onClick={() => onROIPdfExport()}>ROI PDF</Dropdown.Item>
                                </DropdownButton>
                        }
                    </div>
                    }
                </div>
                <ProgressBar hidden={!loading} animated now={progressNow} />
                <div className="ag-theme-quartz" style={{ height: 700 }}>
                    <AgGridReact loading={loading} ref={gridRef} rowData={assetData} statusBar={statusBar} columnDefs={colDefs} defaultColDef={defaultColDef} sideBar={sideBar} rowSelection={rowSelection} rowGroupPanelShow={"always"} 
                        rowStyle={{ cursor: "pointer" }}
                        onRowSelected={(row) => {
                            handleChecked(row.data)
                        }
                    } />
                </div>

                <CreateAssetDialog isOpen={dialogOpen} onSubmit={handleDialogSubmit} assetSuccess={(successStatus) => setAssetSuccess(successStatus)}
                    buildingId={buildingId} productDropDown={productDropdownData} />

                <Modal show={inspectionPreviewOpen} size="xl">
                    <Modal.Body>
                        <div className="modalinspection">
                            <Button onClick={handleClose} type="button" className="btn btn-secondary">
                                Close
                            </Button>
                        </div>
                        <InspectionPreview onSubmit={handleInspectionPreviewDialogClose}
                            inspectionData={{ ...inspectionData }} assetData={...inspectionAssetItemData} pdfDownload="false" />
                    </Modal.Body>
                </Modal>

                <Modal className="modalStyle" show={modal.show} centered>
                    <Modal.Header>
                        <Modal.Title>{setModalTitle(modal.title)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to change the {setModalTitle(modal.title)} value to {setModalBodyText(modal.newValue)} ?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { cellClickEvent(modal.data); }} >Yes</Button>
                        <Button variant="secondary" onClick={() => { cancelQAChange(modal.data); closeQAModal(); undo() }}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal className="modalStyle" show={assetMoveOpen} size="lg" centered>
                    <Modal.Header>
                        <Modal.Title>Move Assets to Building</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ height: "600px" }}>
                        <Dropdown style={{ width: "775px" }} >
                            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                {assetBuildingMove === "" ? "Select a Building" : buildingData.find(it => it.id == assetBuildingMove).name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu show as={CustomMenu} style={{ height: "500px", overflowY: "auto" }} >
                                {buildingData?.map((item) => (
                                    <Dropdown.Item key={item.id} onClick={() => setAssetBuildingMove(item.id)}>
                                        {item.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        {assetBuildingMove === "" ? "" : 
                            <Button variant="secondary" onClick={() => { moveAsset(); setAssetMoveOpen(false); setAssetBuildingMove(""); setRowActionPending(true); }} >Save</Button>
                        }
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setAssetBuildingMove(""); setAssetMoveOpen(false); }}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Modal className="modalStyle" show={modalGridConfigOpen} centered size="lg">
                    <Modal.Header>
                        <Modal.Title>Grid Configuration</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ overflow: "hidden", marginTop: "20px" }}>
                            <div style={{ width: "52%", float: "left" }}>
                                <div>
                                    <div>Load a Grid Configuration</div>
                                    <Form.Select required className={"d-grid"} size="lg" style={{ width: "285px", float: "left" }} onChange={userConfigChange}>
                                        <option key={0} value="" selected disabled></option>
                                        {userGridConfigsData?.map((value, index) => {
                                            return (<option key={index} value={value.id}>{value.description}</option>);
                                        })}
                                    </Form.Select>
                                </div>
                                {
                                    selectedUserGridConfig === null || selectedUserGridConfig?.id === "" ? "" :
                                        <>
                                            <div style={{ marginLeft: "73%" }}><Form.Check checked={selectedUserGridConfig === null || selectedUserGridConfig.isDefault === false ? false : true} type='checkbox' id={`default-check`} label={`Default`} onClick={(e) => { requestConfigsUpdateDefault(selectedUserGridConfig.id, e); }} /></div>
                                            <div style={{ marginLeft: "73%" }}><Button style={{ height: "20px", lineHeight: "10px", width: "66%" }} variant="danger" size="sm" onClick={(e) => { deleteUserConfigData(selectedUserGridConfig.id); }}>Delete</Button></div>
                                    </>
                                }

                            </div>
                            <div style={{ width: "48%", overflow: "hidden" }}>
                                <div>Save The Current Grid Configuration</div>
                                <Form noValidate validated={validated} onSubmit={addPageSetting}>
                                    <Form.Group controlId="username">
                                        <Form.Control required className="modal-input" size="lg" type="text" placeholder="Description Name" style={{ width: "300px", float: "left" }} onChange={e => setGridConfigDescription(e.target.value)} />
                                        <button className="btn btn-link" type="submit"><i className="fa-solid fa-save fa-2x clickable hoverable"></i></button>
                                        <Form.Control.Feedback type="invalid">
                                            Descirption cannot be Empty
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setModalGridConfigOpen(false); setSelectedUserGridConfig(null) }}>Close</Button>
                    </Modal.Footer>
                </Modal>
            
            </div>
        </>
    );
};

