import React from "react";
import { RoiObject, mpInfoObject } from "../common/types";
import { observer, useLocalObservable } from 'mobx-react';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { azureStorageDomain } from "../common/clientConfig";

export type RoiProps = {
    roiData?: RoiObject
}

export const RoiMetering = observer((props: RoiProps) => {

    var roiData = props.roiData;

    return (
        <div className="row-container" id="roi-container">
            <div style={{ display: "ruby" }}>
                <div className="roi-header">
                    <img src={azureStorageDomain + "/qmc/logo.png"} className="roi-header-logo" />
                    <div className="roi-header-text">Record of Meter Installation<br /><span style={{ color: "#FF5733" }}>{roiData?.asset?.manufacturer == null ? "" : roiData.asset?.manufacturer} {roiData?.asset?.model == null ? "" : roiData.asset?.model} Meter</span></div>
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">General Info</div>
                </div>

                <div className="roi-body-container-half">
                    <Table className="roiTableStyle" id="gen1-table">
                        <tr>
                            <th style={{ paddingTop: "5px", width: "150px" }}>Client:</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.meterOwnerName == null ? "" : roiData.sealingInfo?.meterOwnerName}</td>
                        </tr>
                        <tr>
                            <th>Address:</th>
                            <td>{roiData?.asset?.building == null ? "" : roiData.asset?.building.address + " " + roiData.asset?.building.city + ", " + roiData.asset?.building.province}</td>
                        </tr>
                        <tr>
                            <th>QMC Reference:</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.workOrder == null ? "" : roiData.sealingInfo?.workOrder}</td>
                        </tr>
                        <tr>
                            <th>Contractor No.</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.contractorNumber == null ? "" : roiData.sealingInfo?.contractorNumber}</td>
                        </tr>
                    </Table>
                </div>
                <div className="roi-body-container-half roi-body-container-right">
                    <Table className="roiTableStyle" id="gen2-table">
                        <tr>
                            <th style={{ paddingTop: "5px", width: "150px" }}>Serial No.</th>
                            <td>{roiData?.asset == null || roiData.asset?.serialNumber == null ? "" : roiData.asset?.serialNumber}</td>
                            <th style={{ paddingTop: "5px", width: "150px" }}>Approval No.</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.contractorNumber == null ? "" : roiData.sealingInfo?.approvalNumber}</td>
                        </tr>
                        <tr>
                            <th>Inspection No.</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.inspectionNumber == null ? <span>&nbsp;</span> : roiData.sealingInfo?.inspectionNumber}</td>
                            <th>Installer Name:</th>
                            <td>{questionResponse(roiData, "Installer_Name") == "" ? "" : questionResponse(roiData, "Installer_Name")}</td>
                        </tr>
                        <tr>
                            <th>Installation Date:</th>
                            <td>{questionResponse(roiData, "Installation_Date") == "" ? "" : questionResponse(roiData, "Installation_Date")}</td>
                            <th>Sealing Year:</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.sealDate == null ? "" : moment(roiData.sealingInfo?.sealDate).format('YYYY')}</td>
                        </tr>
                        <tr>
                            <th>Meter Location:</th>
                            <td>{questionResponse(roiData, "Physical_Location") == "" ? "" : questionResponse(roiData, "Physical_Location")}</td>
                            <th>&nbsp;</th>
                            <td>&nbsp;</td>
                        </tr>
                    </Table>
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">Sealing Parameters</div>
                </div>

                <div className="roi-body-container-half">
                    <Table className="roiTableStyle" id="seal1-table" style={{ height: "67px" }}>
                        <tr>
                            <th style={{ width: "175px" }}>Sealing Parameters:</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.sealParameters == null ? "" : roiData.sealingInfo?.sealParameters.map((txt, i, list) => i + 1 === list.length ? txt : txt + ", ")}</td>
                        </tr>
                        <tr>
                            <th style={{ width: "175px" }}>&nbsp;</th>
                            <td>&nbsp;</td>
                        </tr>
                    </Table>
                </div>
                <div className="roi-body-container-half roi-body-container-right">
                    <Table className="roiTableStyle" id="seal2-table">
                        <tr>
                            <th style={{ width: "150px" }}>Meter Configuration:</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.meterConfiguration == null ? "" : meterConfigurationDrop.find(it => it.value == roiData.sealingInfo?.meterConfiguration).name}</td>
                            <th style={{ width: "150px" }}>Interval (min):</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.demandInterval == null ? "" : roiData.sealingInfo?.demandInterval}</td>
                        </tr>
                        <tr>
                            <th style={{ width: "150px" }}>Number Elements</th>
                            <td>{roiData?.sealingInfo == null || roiData.sealingInfo?.elements == null ? "" : roiData.sealingInfo?.elements}</td>
                            <th style={{ width: "150px" }}>&nbsp;</th>
                            <td>&nbsp;</td>
                        </tr>
                    </Table>
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">Reference Voltage</div>
                </div>

                <div className="roi-body-container-table-half">
                    <Table id="volt1-table" className="table table-sm" style={{ textAlign: "center" }}>
                        <thead>
                            <tr style={{ borderBottom: "2px solid" }}>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Voltage 1</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Prim.</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Sec.</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>PT Serial</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Ratio</th>
                                <th>Volts</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase A</td>
                                <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{questionResponse(roiData, "REF_Voltage")}</td>
                                <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3} className="value-orange">{roiData?.sealingInfo == null || roiData.sealingInfo?.ptSerialNumber == null ? "" : roiData.sealingInfo?.ptSerialNumber}</td>
                                <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{questionResponse(roiData, "PT_Ratio")}</td>
                                <td>{formatTwoDecimal(questionResponse(roiData, "REF_PhaseA_Voltage"))}</td>
                            </tr>
                            <tr>
                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase B</td>
                                <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                <td>{formatTwoDecimal(questionResponse(roiData, "REF_PhaseB_Voltage"))}</td>
                            </tr>
                            <tr>
                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase C</td>
                                <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                <td>{formatTwoDecimal(questionResponse(roiData, "REF_PhaseC_Voltage"))}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="roi-body-container-table-half roi-body-container-right">
                    {
                        roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "REF2_PhaseA_Voltage_Ref2") == null ?

                            <Table id="volt2-table" className="table table-sm" style={{ marginTop: '15px', textAlign: "center", height: "100%" }}>
                                <tr>
                                    <td style={{ color: "lightgray", fontSize: '2rem', fontWeight: 'bold' }} >No Ref Voltage 2 Data</td>
                                </tr>
                            </Table>

                            :

                            <Table id="volt2-table" className="table table-sm" style={{ marginTop: '15px', textAlign: "center" }}>
                                <thead>
                                    <tr style={{ borderBottom: "2px solid" }}>
                                        <th style={{ borderRight: "solid 1px #E0E0E0" }}>Voltage 2</th>
                                        <th style={{ borderRight: "solid 1px #E0E0E0" }}>Prim.</th>
                                        <th style={{ borderRight: "solid 1px #E0E0E0" }}>Sec.</th>
                                        <th style={{ borderRight: "solid 1px #E0E0E0" }}>PT Serial</th>
                                        <th style={{ borderRight: "solid 1px #E0E0E0" }}>Ratio</th>
                                        <th style={{ borderRight: "solid 1px #E0E0E0" }}>Volts</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase A</td>
                                        <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{questionResponse(roiData, "REF2_Voltage")}</td>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                        <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3} className="value-orange">{questionResponse(roiData, "PT_SN_Ref2")}</td>
                                        <td style={{ verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }} rowSpan={3}>{questionResponse(roiData, "PT_Ratio_Ref2")}</td>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}>{formatTwoDecimal(questionResponse(roiData, "REF2_PhaseA_Voltage_Ref2"))}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase B</td>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                        <td>{formatTwoDecimal(questionResponse(roiData, "REF2_PhaseB_Voltage_Ref2"))}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}>Phase C</td>
                                        <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                        <td>{formatTwoDecimal(questionResponse(roiData, "REF2_PhaseC_Voltage_Ref2"))}</td>
                                    </tr>
                                </tbody>
                            </Table>}
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">Meter Point Information</div>
                </div>

                <div className="roi-body-container-table-full">
                    <Table id="mp-table" className="table table-sm" style={{ borderCollapse: 'collapse', textAlign: "center" }} >
                        <thead>
                            <tr style={{ borderBottom: "2px solid" }}>
                                <th style={{ textAlign: 'center', borderRight: "solid 1px #E0E0E0" }}>Meter<br />Point</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>MP-ID</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Vref</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Phase</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Amps</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Watts</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Phase<br />Angle</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Power<br />Factor</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>CT<br />Approval #</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>CT<br />Serial No.</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>CT Ratio</th>
                                <th style={{ borderRight: "solid 1px #E0E0E0" }}>Accepted<br />(Yes/No)</th>
                                <th style={{ textAlign: 'center', borderRight: "solid 1px #E0E0E0" }}>Panel / Desciption</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...Array(roiData?.sealingInfo?.numMeteringPoints)].map((x, i) => (
                                <React.Fragment key={i}>

                                    {comminssioningNullCheck(roiData?.commissioningData, i, false, false) || roiData?.commissioningData.mpInfo[i].ctInfo.length == 0 ?
                                        <tr style={{ borderBottom: '1px solid black' }} >
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }}>{i + 1}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}></td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : roiData.commissioningData.mpInfo[i].referenceVoltageNum}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].phase}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].amperage}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : formatTwoDecimal(roiData.commissioningData.mpInfo[i].ctInfo[0].power)}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].phaseAngle}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].powerFactor}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].mcApproval}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo == null ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].sn}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : roiData.commissioningData.mpInfo[i].ctRatio}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : mpInfoAcceptedValue(roiData.commissioningData.mpInfo[i])}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : formatAddress(roiData?.commissioningData, i) + formatBreaker(roiData.commissioningData.mpInfo[i])}</td>
                                        </tr>
                                        :
                                        <tr style={{ borderBottom: roiData?.commissioningData.mpInfo[i].ctInfo.length == 1 ? '1px solid black' : '' }}>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0", borderBottom: '1px solid black' }} rowSpan={comminssioningNullCheck(roiData?.commissioningData, i, false, false) ? 1 : roiData?.commissioningData.mpInfo[i].ctInfo.length}>{i + 1}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-1</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0", borderBottom: '1px solid black' }} rowSpan={comminssioningNullCheck(roiData?.commissioningData, i, false, false) ? 1 : roiData?.commissioningData.mpInfo[i].ctInfo.length}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : roiData.commissioningData.mpInfo[i].referenceVoltageNum}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].phase}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].amperage}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : formatTwoDecimal(roiData.commissioningData.mpInfo[i].ctInfo[0].power)}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].phaseAngle}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].powerFactor}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].mcApproval}</td>
                                            <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo == null ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].sn}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0", borderBottom: '1px solid black' }} rowSpan={comminssioningNullCheck(roiData?.commissioningData, i, false, false) ? 1 : roiData?.commissioningData.mpInfo[i].ctInfo.length}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : roiData.commissioningData.mpInfo[i].ctRatio}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0", borderBottom: '1px solid black' }} rowSpan={comminssioningNullCheck(roiData?.commissioningData, i, false, false) ? 1 : comminssioningNullCheck(roiData?.commissioningData, i, false, false) ? 0 : roiData?.commissioningData.mpInfo[i].ctInfo.length}>{comminssioningNullCheck(roiData?.commissioningData, i, false, true) ? "" : mpInfoAcceptedValue(roiData.commissioningData.mpInfo[i])}</td>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle', borderRight: "solid 1px #E0E0E0", borderBottom: '1px solid black' }} rowSpan={comminssioningNullCheck(roiData?.commissioningData, i, false, false) ? 1 : roiData?.commissioningData.mpInfo[i].ctInfo.length}>{formatAddress(roiData?.commissioningData, i) + formatBreaker(roiData.commissioningData.mpInfo[i])}</td>
                                        </tr>
                                    }
                                    {
                                        !comminssioningNullCheck(roiData?.commissioningData, i, true, false) && roiData.commissioningData.mpInfo[i].ctInfo[1] != null ?
                                            <tr style={{ borderBottom: roiData?.commissioningData.mpInfo[i].ctInfo.length == 2 ? '1px solid black' : '' }}>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-2</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].phase}</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].amperage}</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : formatTwoDecimal(roiData.commissioningData.mpInfo[i].ctInfo[1].power)}</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].phaseAngle}</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[0].powerFactor}</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].mcApproval}</td>
                                                <td style={{ borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[1].sn}</td>
                                            </tr>
                                            :
                                            ""
                                    }

                                    {
                                        !comminssioningNullCheck(roiData?.commissioningData, i, true, false) && roiData.commissioningData.mpInfo[i].ctInfo[2] != null ?
                                            <tr style={{ borderBottom: roiData?.commissioningData.mpInfo[i].ctInfo.length == 3 ? '1px solid black' : '' }}>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>MP{i + 1}-3</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].phase}</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].amperage}</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : formatTwoDecimal(roiData.commissioningData.mpInfo[i].ctInfo[2].power)}</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].phaseAngle}</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].powerFactor}</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].mcApproval}</td>
                                                <td style={{ borderBottom: '1px solid black', borderRight: "solid 1px #E0E0E0" }}>{comminssioningNullCheck(roiData?.commissioningData, i, true, true) ? "" : roiData.commissioningData.mpInfo[i].ctInfo[2].sn}</td>
                                            </tr>
                                            :
                                            ""
                                    }

                                </React.Fragment>
                            )
                            )}
                        </tbody>
                    </Table>
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">Communications</div>
                </div>

                <div className="roi-body-container-full">

                    {roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "Communication_Ethernet") == null ? "" :
                        <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                            <Table className="roiTableStyle" id="comEth-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2} style={{ height: "2px", padding: "1px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }} >Ethernet</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>MAC:</th>
                                    <td>{questionResponse(roiData, "MAC_Address")}</td>
                                </tr>
                                <tr>
                                    <th>IP:</th>
                                    <td>{questionResponse(roiData, "Ethernet_IP")}</td>
                                </tr>
                                <tr>
                                    <th>Subnet:</th>
                                    <td>{questionResponse(roiData, "Ethernet_Subnet_Mask")}</td>
                                </tr>
                                <tr>
                                    <th>Gateway:</th>
                                    <td>{questionResponse(roiData, "Ethernet_Gateway_IP")}</td>
                                </tr>
                                <tr>
                                    <th>DNS:</th>
                                    <td>{questionResponse(roiData, "Ethernet_DNS")}</td>
                                </tr>
                                <tr>
                                    <th>Time Server:</th>
                                    <td>{questionResponse(roiData, "Ethernet_Time_Server")}</td>
                                </tr>
                            </Table>
                        </div>
                    }

                    {roiData?.inspection == null || (roiData.inspection.answers.find(x => x.questionId === "Communication_FTP") == null && roiData.inspection.answers.find(x => x.questionId === "Communication_Https")) == null ? "" :
                        <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                            <Table className="roiTableStyle" id="comFtp-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2} style={{ height: "2px", padding: "1px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }} >FTP / HTTPS</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>FTP Server:</th>
                                    <td>{questionResponse(roiData, "FTP_Path")}</td>
                                </tr>
                                <tr>
                                    <th>Port:</th>
                                    <td>{questionResponse(roiData, "FTP_Port")}</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>HTTPS:</th>
                                    <td>{questionResponse(roiData, "HTTPs_Enabled")}</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                            </Table>
                        </div>
                    }

                    {roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "Communication_Bacnet_MS_TP") == null ? "" :
                        <div style={{ width: "27%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                            <Table className="roiTableStyle" id="comBacnet-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2} style={{ height: "2px", padding: "1px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }} >BACnet MSTP</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>BACnet ID:</th>
                                    <td>{questionResponse(roiData, "BacnetMSTP_Device_ID")}</td>
                                </tr>
                                <tr>
                                    <th>Object ID:</th>
                                    <td>{questionResponse(roiData, "BacnetMSTP_Object_ID")}</td>
                                </tr>
                                <tr>
                                    <th>Baudrate:</th>
                                    <td>{questionResponse(roiData, "BacnetMSTP_Baud Rate")}</td>
                                </tr>
                                <tr>
                                    <th>Parity:</th>
                                    <td>{questionResponse(roiData, "Bacnet_Parity")}</td>
                                </tr>
                                <tr>
                                    <th>Stop Bit:</th>
                                    <td>{questionResponse(roiData, "Bacnet_Stop_Bit")}</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                            </Table>
                        </div>
                    }

                    {roiData?.inspection == null || roiData.inspection.answers.find(x => x.questionId === "Communication_Modbus_RTU") == null ? "" :
                        <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                            <Table className="roiTableStyle" id="comModbus-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2} style={{ height: "2px", padding: "1px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }} >Modbus RTU</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>Modbus ID:</th>
                                    <td>{questionResponse(roiData, "ModbusRTU_ID")}</td>
                                </tr>
                                <tr>
                                    <th>Bit:</th>
                                    <td>{questionResponse(roiData, "Modbus_Bit")}</td>
                                </tr>
                                <tr>
                                    <th>Baudrate:</th>
                                    <td>{questionResponse(roiData, "Modbus_Baud_Rate")}</td>
                                </tr>
                                <tr>
                                    <th>Parity:</th>
                                    <td>{questionResponse(roiData, "Modbus_Parity")}</td>
                                </tr>
                                <tr>
                                    <th>Stop Bit:</th>
                                    <td>{questionResponse(roiData, "Modbus_Stop_Bit")}</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                            </Table>
                        </div>
                    }

                    {roiData?.inspection == null || (roiData.inspection.answers.find(x => x.questionId === "Communication_Modbus_TCP") == null && roiData.inspection.answers.find(x => x.questionId === "Communication_Bacnet_IP")) == null ? "" :
                        <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                            <Table className="roiTableStyle" id="comModBac-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2} style={{ height: "2px", padding: "1px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }} >Modbus TCP/BACnet IP</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>BACnet ID:</th>
                                    <td>{questionResponse(roiData, "BacnetIP_Device_ID")}</td>
                                </tr>
                                <tr>
                                    <th>BACnet Port:</th>
                                    <td>{questionResponse(roiData, "BacnetIP_Port")}</td>
                                </tr>
                                <tr>
                                    <th>Object ID:</th>
                                    <td>{questionResponse(roiData, "BacnetIP_Object_ID")}</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>Modbus ID:</th>
                                    <td>{questionResponse(roiData, "ModbusTCP_ID")}</td>
                                </tr>
                                <tr>
                                    <th>Modbus Port:</th>
                                    <td>{questionResponse(roiData, "ModbusTCP_Port")}</td>
                                </tr>
                            </Table>
                        </div>
                    }

                    {roiData?.inspection == null || (roiData.inspection.answers.find(x => x.questionId === "Communication_CellModem") == null && roiData.inspection.answers.find(x => x.questionId === "Communication_LoRaWAN")) == null ? "" :
                        <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left" }}>
                            <Table className="roiTableStyle" id="comWire-table">
                                <thead >
                                    <tr>
                                        <th colSpan={2} style={{ height: "2px", padding: "1px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }} >Wireless Option</th>
                                    </tr>
                                </thead>
                                <tr>
                                    <th>BACnet ID:</th>
                                    <td>{questionResponse(roiData, "BacnetIP_Device_ID")}</td>
                                </tr>
                                <tr>
                                    <th>BACnet Port:</th>
                                    <td>{questionResponse(roiData, "BacnetIP_Port")}</td>
                                </tr>
                                <tr>
                                    <th>Object ID:</th>
                                    <td>{questionResponse(roiData, "BacnetIP_Object_ID")}</td>
                                </tr>
                                <tr>
                                    <th>&nbsp;</th>
                                    <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <th>Modbus ID:</th>
                                    <td>{questionResponse(roiData, "ModbusTCP_ID")}</td>
                                </tr>
                                <tr>
                                    <th>Modbus Port:</th>
                                    <td>{questionResponse(roiData, "ModbusTCP_Port")}</td>
                                </tr>
                            </Table>
                        </div>
                    }

                    <div style={{ width: "17%", height: "160px", border: "1px solid", margin: "10px 0px 10px 10px", float: "left", display: "none" }}>
                        <div style={{ width: "100%", height: "20px", backgroundColor: "cornflowerblue", color: "white", textAlign: "center" }}>Others</div>
                        <div style={{ padding: "4px", width: "100%" }}>
                            <div style={{ width: "100%" }}>&nbsp;</div>
                        </div>
                    </div>
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">Notes</div>
                </div>

                <div className="roi-body-container-full" style={{ padding: "5px" }}>
                    <Table id="notes-table">
                        {roiData?.notes == null ? <tr><td>&nbsp;</td></tr> : roiData.notes.map((value, i) => (value === "" ? <tr key={i}><td>&nbsp;</td></tr> : <tr key={i}><td>{value}</td></tr>))}
                    </Table>
                </div>

                <div className="roi-body-header">
                    <div className="roi-body-header-text">Sealing Info</div>
                </div>

                <div className="roi-body-container-half-sealing">
                    <Table className="roiTableStyle" id="sealInfo1-table">
                        <tr>
                            <th>&nbsp;</th>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Inspection Status:</th>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th style={{ fontWeight: "normal" }}>Installation Tested and Verified:</th>
                            <td><Form.Check id="install1" style={{ backgroundColor: "transparent", padding: "0px" }} type="checkbox" checked={roiData?.asset == null || roiData.asset?.status == "Pass" ? true : false} /></td>
                        </tr>
                        <tr>
                            <th style={{ fontWeight: "normal" }}>Installation Tested, Corrected and Verified (see Notes):</th>
                            <td><Form.Check id="install2" style={{ backgroundColor: "transparent", padding: "0px" }} type="checkbox" checked={false} /></td>
                        </tr>
                        <tr>
                            <th style={{ fontWeight: "normal" }}>Installation Rejected:</th>
                            <td><Form.Check id="install3" style={{ backgroundColor: "transparent", padding: "0px" }} type="checkbox" checked={roiData?.asset == null || roiData.asset?.status == "Fail" ? true : false} /></td>
                        </tr>
                        <tr>
                            <th>QMC Seal:</th>
                            <td><div style={{ backgroundColor: "transparent" }} className="roi-body-container-signed">{questionResponse(roiData, "Seal_Number") == "" ? <span>&nbsp;</span> : questionResponse(roiData, "Seal_Number")}</div></td>
                        </tr>
                    </Table>
                </div>
                <div className="roi-body-container-half-sealing roi-body-container-right">
                    <Table className="roiTableStyle" id="sealInfo2-table">
                        <tr>
                            <th>&nbsp;</th>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <th>Commissioned Date:</th>
                            <td>{roiData?.inspection == null ? "" : moment(roiData.inspection.completionDate).format('yyyy-MM-DD HH:mm')}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Commissioned By:</th>
                            <td className="roi-body-container-signed">{roiData?.inspection == null ? "" : roiData.inspection.creatorName}</td>
                            <td style={{ fontSize: "11px" }}>
                                {roiData?.inspection == null ? "" : roiData.inspection.creatorEmail}
                                {roiData?.inspection == null ? "" : moment(roiData.inspection.completionDate).format('yyyy-MM-DD HH:mm')}
                            </td>
                        </tr>
                        <tr>
                            <th>S-E-04 Inspection Date:</th>
                            <td className="roi-body-container-signed">{roiData?.inspection == null ? "" : moment(roiData.inspection.completionDate).format('yyyy-MM-DD HH:mm')}</td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>S-E-04 Inspection By:</th>
                            <td className="roi-body-container-signed">{roiData?.asset.techReview == null || roiData.asset.techReview.updatedBy == "" ? <span style={{ backgroundColor: "transparent" }}>&nbsp;</span> : roiData.asset.techReview.updatedBy}</td>
                            <td style={{ fontSize: "11px" }}>{roiData?.asset == null || roiData.asset.techReview == null || roiData.asset.techReview.updatedByEmail == null || roiData.asset.techReview.updatedByEmail === "" ? <span style={{ backgroundColor: "transparent" }}>&nbsp;</span> : "(" + roiData.asset.techReview.updatedByEmail + ")"} {roiData?.asset == null ? "" : dateFormat(roiData.asset.techReview.completionDate)}</td>
                        </tr>
                        {roiData?.creationDate == "0001-01-01T00:00:00" ?
                            <tr>
                                <th>&nbsp;</th>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            :
                            <tr>
                                <th>Signed By:</th>
                                <td style={{ fontFamily: "Parisienne, cursive", fontWeight: "600", fontStyle: "normal", fontSize: "17px" }}>{roiData?.inspection.creatorName}</td>
                                <td style={{ fontSize: "11px" }}>{moment(roiData?.inspection.creationDate).format('yyyy-MM-DD HH:mm')}</td>
                            </tr>
                        }
                    </Table>
                </div>

            </div>
        </div>
    );
});

function questionResponse(roiInfo: RoiObject, question: string) {
    if (roiInfo == null) {
        return "N/A";
    } else if (roiInfo?.inspection == null) {
        return "N/A";
    } else if (roiInfo.inspection.answers.find(x => x.questionId === question) == null) {
        return "N/A";
    } else if (roiInfo.inspection.answers.find(x => x.questionId === question).response === null || roiInfo.inspection.answers.find(x => x.questionId === question).response === "") {
        return "N/A";
    } else {
        return roiInfo.inspection.answers.find(x => x.questionId === question).response;
    }
}

const meterConfigurationDrop = [
    { value: '1', name: 'Single Phase' },
    { value: '2', name: '2 Phase Network' },
    { value: '3', name: '3 Phase Delta' },
    { value: '4', name: '3 Phase' }
];

function formatTwoDecimal(value) {
    if (value === null) {
        return "N/A";
    } else {
        return Math.round(value * 100)/100;
    }

}

function comminssioningNullCheck(commissioningData, index, checkCtInfo: boolean, textDisplay: boolean) {
    if (textDisplay && commissioningData != null && commissioningData.mpInfo[index] != null && commissioningData.mpInfo[index].complete == false) {
        return true;
    } else if (commissioningData == null || commissioningData.mpInfo[index] == null) {
        return true;
    } else if (checkCtInfo && commissioningData.mpInfo[index].ctInfo == null) {
        return true
    } else {
        return false
    }
}

function formatAddress(commissioningData, index) {
    let addressString = "";
    if (commissioningData == null || commissioningData.mpInfo[index] == null || commissioningData.mpInfo[index].complete == false) {
        return addressString;
    } else {
        if (commissioningData.mpInfo[index].unitNumber != null) {
            addressString = commissioningData.mpInfo[index].unitNumber;
        }
        if (commissioningData.mpInfo[index].unitName != null) {
            addressString = addressString === "" ? commissioningData.mpInfo[index].unitName : addressString + " - " + commissioningData.mpInfo[index].unitName;
        }
        if (commissioningData.mpInfo[index].serviceAddress != null) {
            addressString = addressString === "" ? commissioningData.mpInfo[index].serviceAddress : addressString + " - " + commissioningData.mpInfo[index].serviceAddress;
        }
        return addressString;
    }
}

function formatBreaker(mpInfo: mpInfoObject) {
    let breakerText = "";

    mpInfo.ctInfo.map((ctinfo) => {
        if (ctinfo.breaker != null && ctinfo.breaker != "") {
            if (breakerText == "") {
                breakerText = " - BKR " + breakerText + ctinfo.breaker;
            } else {
                breakerText = breakerText + "/" + ctinfo.breaker;
            }
        }
    });

    return breakerText;
}

function mpInfoAcceptedValue(mpInfo: mpInfoObject) {
    if (mpInfo == null) {
        return "";
    } else if ((mpInfo?.commissioning == null) || (mpInfo?.commissioning === "") || (mpInfo?.commissioning.toLowerCase() === ("empty")) || (mpInfo?.commissioning.toLowerCase() === "spare")) {
        return "N/A";
    } else {
        return mpInfo?.commissioning;
    }
}

function dateFormat(dateValue: string) {
    var dateSetting = moment("01.01.1980 23:59:00", "DD.MM.YYYY HH.mm.ss");
    if ((dateValue == null) || (moment(dateValue).isBefore(dateSetting))) {
        return <span>&nbsp;</span>;
    } else {
        return moment(dateValue).format('yyyy-MM-DD HH:mm');
    }
}