import React, { useEffect } from 'react';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import NavBarItem from './NavBarItem';
import { useAuthFetch } from './useAuthFetch';

import { roleDev } from './components/RoleValidation';
import { roleAdmin } from './components/RoleValidation';
import { roleProductManager } from './components/RoleValidation';
import { requestAppInfo } from "./hooks/AppInfo";
import { appInfo } from "./common/types";

export type NavBarProps = {
    setSearchModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function NavBar(props: NavBarProps) {

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const authFetch = useAuthFetch();
    const [appInfo, setAppInfo] = useState<appInfo>();

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            if (searchQuery != '') {
                navigate(`search/${searchQuery}`)
                setSearchQuery('');
            }
        }
    };

    useEffect(() => { getAppInfo() }, []);

    const getAppInfo = async () => {
        await requestAppInfo(authFetch).then(res => { return res; }).then((data) => { setAppInfo(data); });
    }

    return (
        <>
            <div>
                <div className="sidebar  justify-content-center text-muted prevent-select">
                    <div style={{ height: "78px", background: "#343a40" }} >
                        <span id="menu-close">
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                    <div className="navbar">
                        <li className="menu-item menu-item-searchbar">
                            <div className="has-search">
                                <span className="fa fa-search form-control-feedback form-control-sm"></span>
                                <input type="text" id="searchinput" className="form-control form-control-sm" placeholder="Search" onKeyDown={handleKeyDown} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                            </div>
                        </li>
                        <li className="menu-item menu-item-searchicon" style={{ display: "none" }} onClick={() => props.setSearchModalOpen(true)} >
                            <div className="menu-item-icon">
                                <i className="fa fa-search"></i>
                            </div>
                        </li>
                        {true ? "" : <NavBarItem text="Dashboard" path="/dashboard" icon="fa-solid fa-house-user" />}
                        <NavBarItem text="Assets" path="/assets" icon="fa-solid fa-microchip" />
                        <NavBarItem text="Buildings" path="/buildings" icon="fa-regular fa-building" />
                        {roleDev() || roleAdmin() || roleProductManager() ? <NavBarItem text="Products" path="/products" icon="fa-solid fa-book-open" /> : ""}
                        {roleDev() || roleAdmin() || roleProductManager() ? <NavBarItem text="Templates" path="/templates" icon="fa-regular fa-rectangle-list" /> : ""}
                        {roleDev() ? <NavBarItem text="Test" path="/test" icon="fa-solid fa-code" /> : ""}
                        {roleAdmin() ? <NavBarItem text="Dev" path="/dev" icon="fa-solid fa-code" /> : ""}
                        {roleDev() || roleAdmin() || roleProductManager() ?<NavBarItem text="QA" path="/qa" icon="fa-regular fa-rectangle-list" /> : "" }
                        <NavBarItem text="Feedback" path="/feedback" icon="fa-solid fa-comment" />
                        <div className="menu-item-spacer"></div>
                        <li className="menu-item menu-item-toggle">
                            <div className="menu-item-icon menu-item-toggle-icon">
                                <i className="fa fa-solid fa-angles-left"></i>
                            </div>
                        </li>
                    </div>
                    <div style={{ height: "100%", overflow: "hidden", margin: "0 auto", fontSize: "10px" }}>
                        <div style={{ position: "absolute", bottom: "18px", fontWeight: "bold", width: "100%", textAlign: "center" }}>MosaiQ API Version:</div>
                        <div style={{ position: "absolute", bottom: "5px", width: "100%", textAlign: "center" }}>{appInfo?.versionMajor}.{appInfo?.versionMinor}.{appInfo?.build}.{appInfo?.revision}</div>
                    </div> 
                </div>
            </div>
        </>
    );
}

