﻿import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthFetch } from '../useAuthFetch';
import { getApiUrl } from '../authConfig';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';

import "../assets/css/roiPage.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import Button from 'react-bootstrap/Button';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { AssetObject, RoiObject } from "../common/types";
import { getROISnapShotData, getROIData, getROIByIdData } from "../hooks/Roi";
import { requestAssetData } from "../hooks/Asset";
import { roleSE04CertSigningAuthority, roleSE04AuthorizedInspector, roleQAController, roleQAManager } from '../components/RoleValidation';

import { LoadingTemplate } from '../LoadingTemplate';
import { RoiMetering } from '../components/RoiMetering';
import { createPDFRoi } from '../hooks/CreatePDF';

export function ROIViewPage() {
    const { accounts } = useMsal();
    const params = useParams();
    const authFetch = useAuthFetch();
    const navigate = useNavigate();
    const assetId = params.assetId;
    const [loading, setLoading] = useState(false);

    const [roiData, setRoiData] = useState<RoiObject>(null);
    const [roiVersionList, setRoiVersionList] = useState<RoiObject[]>([]);
    const [assetList, setAssetList] = useState<AssetObject[]>([]);
    const [dropDownTitle, setDropDownTitle] = useState("");

    function roiToPdf() {
        var jsPDF = createPDFRoi(roiData, null);
        if (roiData?.version === null) {
            jsPDF.save("ROI_" + roiData?.asset.building.address + "_" + roiData?.asset.model + "_" + roiData?.asset.serialNumber + "_" + roiData?.inspection.completionDate.substring(0, roiData.inspection.completionDate.indexOf("T")) + ".pdf");
        } else {
            jsPDF.save("ROI_" + roiData?.asset.building.address + "_" + roiData?.asset.model + "_" + roiData?.asset.serialNumber + "_" + roiData?.inspection.completionDate.substring(0, roiData.inspection.completionDate.indexOf("T")) + "_rev" + roiData?.version + ".pdf");
        }
    }

    function qaStatusDateCheck(item: string) {
        let checkOK = true;

        let fieldCommissioningDate = moment(roiData?.inspection?.completionDate);
        let techReviewDate = moment(roiData?.asset.techReview.completionDate);
        let qaReviewDate = moment(roiData?.asset.qaReview.completionDate);
        let qaApprovalDate = moment(roiData?.asset.qaApproval.completionDate);

        if (item === "qaApproval") {
            if (qaApprovalDate.isBefore(qaReviewDate)) {
                checkOK = false;
            }
            if (qaReviewDate.isBefore(techReviewDate)) {
                checkOK = false;
            }
            if (techReviewDate.isBefore(fieldCommissioningDate)) {
                checkOK = false;
            }
        }

        if (item === "qaReview") {
            if (qaReviewDate.isBefore(techReviewDate)) {
                checkOK = false;
            }
            if (techReviewDate.isBefore(fieldCommissioningDate)) {
                checkOK = false;
            }
        }

        if (item === "techReview") {
            if (techReviewDate.isBefore(fieldCommissioningDate)) {
                checkOK = false;
            }
        }

        return checkOK;
    };

    const roiSignButton = (item: string) => {
        let isOK = qaStatusDateCheck(item);
        if (isOK) {
            return (
                <>
                    <Button style={{marginRight: "4px"}} type="button" variant="secondary" onClick={() => { cellClickEvent(item, 1); }} className="btn btn-primary btn-sm">Pass</Button>
                    <Button type="button" variant="secondary" onClick={() => { cellClickEvent(item, 2); }} className="btn btn-primary btn-sm">Fail</Button> 
                </>
            );
        } else {
            return (
                <>
                    <Button style={{ marginRight: "4px" }} type="button" variant="danger" onClick={() => { cellClickEvent(item, 1); }} className="btn btn-primary btn-sm">Pass</Button>
                    <Button type="button" variant="danger" onClick={() => { cellClickEvent(item, 2); }} className="btn btn-primary btn-sm">Fail</Button>
                </>

            );
        }
    };

    function qaStatusMapping(status: number) {
        let returnValue = "";
        if (status == 0) {
            returnValue = "Incomplete";
        } else if (status == 1) {
            returnValue = "Passed";
        } else if (status == 2) {
            returnValue = "Failed";
        }
        return returnValue;
    }

    const requestInitialData = async (assetIdParam) => {
        getROIData(authFetch, assetIdParam).then(res => { return res; }).then((roiData) => {
            setRoiVersionList(roiData);
            requestAssetData(authFetch, roiData[0].asset.buildingId).then(res => { return res; }).then((data) => {
                setAssetList(data);
                setDropDownTitle(data.find(x => x.id === assetIdParam).model + " - " + data.find(x => x.id === assetIdParam).serialNumber);
            });
            if (roiData[0].id === null) {
                if (roiData.length > 1) {
                    getROIByIdData(authFetch, roiData[1].id).then(res => { return res; }).then((data) => { setRoiData(data); });
                } else {
                    getROISnapShotData(authFetch, assetIdParam).then(res => { return res; }).then((data) => { setRoiData(data); });
                }
            } else {
                getROIByIdData(authFetch, roiData[0].id).then(res => { return res; }).then((data) => { setRoiData(data); });
            }
        });

    }

    useEffect(() => {
        requestInitialData(assetId);
    }, []);

    const assetChange = async (assetChanged: string) => {
        navigate(`/roiview/${assetChanged}`);
        requestInitialData(assetChanged);
    }

    const setROISelect = async (roiId: string) => {
        if (roiId === "0") {
            getROISnapShotData(authFetch, assetId).then(res => { return res; }).then((data) => { setRoiData(data);});
        } else {
            getROIByIdData(authFetch, roiId).then(res => { return res; }).then((data) => { setRoiData(data);});
        }
    }

    const cellClickEvent = async (item: string, status) => {
        let newRoiData = JSON.parse(JSON.stringify(roiData));
        const date = new Date();

        const jsonArray = '{"qaStatus": "' + status + '", "completionDate": "' + date.toISOString() + '", "updatedByEmail": "' + accounts[0]?.username + '",  "updatedBy": "' + accounts[0]?.name + '"}'
        if (item === "techReview") {
            newRoiData.asset.techReview = JSON.parse(jsonArray);
        } else if (item === "qaReview") {
            newRoiData.asset.qaReview = JSON.parse(jsonArray);
        } else if (item === "qaApproval") {
            newRoiData.asset.qaApproval = JSON.parse(jsonArray);
        }   

        let url = getApiUrl("assets");
        url = `${url}/${roiData.asset.id}`;

        let fetchResponse = await authFetch("PUT", url, { ...newRoiData.asset, buildingId: roiData.asset.buildingId })
        if (fetchResponse.status != 409) {
            //console.log("UPDATED!");
        } else {
            console.log("ERROR");
        }

        setRoiData(newRoiData);

        if (item === "qaApproval") {
            let urlROI = getApiUrl("rois");
            urlROI = `${urlROI}?assetId=${roiData.asset.id}`;

            let fetchResponse = await authFetch("POST", urlROI, { ...newRoiData.asset })
            if (fetchResponse.status != 409) {
                //console.log("UPDATED!");
            } else {
                console.log("ERROR");
            }
        }
    }

    function dateFormat(dateValue: string) {
        var dateSetting = moment("01.01.1980 23:59:00", "DD.MM.YYYY HH.mm.ss");
        if ((dateValue == null) || (moment(dateValue).isBefore(dateSetting))) {
            return <span>&nbsp;</span>;
        } else {
            return moment(dateValue).format('yyyy-MM-DD HH:mm');
        }
    }

    function emptyStringFormat(value: string) {
        if ((value == null) || (value === "")) {
            return <span>&nbsp;</span>;
        } else {
            return value;
        }
    }

    return (
        <LoadingTemplate isLoading={loading}>
                <div style={{ float: "left", padding: "10px", width: "80%" }}>
                    <div style={{ float: "left" }} >
                    <div className="dropdown-label">Select an Asset</div>
                    <DropdownButton style={{ float: "left" }} variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={dropDownTitle}>
                            {assetList.map((item) => (
                                item.assetType === "Electricity" ?
                                    <Dropdown.Item style={{ height: "30px" }} key={item.id} onClick={() => { assetChange(item.id); }}>
                                        <div style={{ float: "left", paddingRight: "5px" }}>{item.model + " - " + item.serialNumber} </div>
                                    </Dropdown.Item>
                                : ""
                            ))
                            }
                        </DropdownButton>
                    </div>

                    <div style={{ float: "left", marginLeft: "5px" }}>
                        <div className="dropdown-label">ROI Version</div>
                        <DropdownButton style={{ float: "left" }} variant="outline-secondary" className="page-dropdown" id="dropdown-basic" title={roiData?.creationDate === "0001-01-01T00:00:00" ? "Current SnapShot" : (moment(roiData?.creationDate).format('MMM DD YYYY, h:mm A') + (roiData?.version === null ? "" : " (Rev" + roiData?.version + ")"))}>
                            {roiVersionList.map((item) => (
                                item.id === null ?
                                    <Dropdown.Item style={{ height: "30px" }} onClick={() => { setROISelect("0"); }}>
                                        <div style={{ float: "left", paddingRight: "5px" }}>Current SnapShot</div>
                                    </Dropdown.Item> :
                                    <Dropdown.Item style={{ height: "30px" }} key={item.id} onClick={() => { setROISelect(item.id); }}>
                                        <div style={{ float: "left", paddingRight: "5px" }}>{moment(item.creationDate).format('MMM DD YYYY, h:mm A')}</div>
                                        {item.version === null || item.version == 0 ? "" : < div style={{ overflow: "hidden" }}>(Rev {item.version})</div>}
                                    </Dropdown.Item>
                            ))
                            }
                        </DropdownButton>
                    </div>

                    {
                        roiVersionList.length > 1 && roiVersionList[0].id === null ?
                        < div style={{ color: "darkred", height: "55px", lineHeight: "55px", fontWeight: "bold", float: "left", marginLeft: "10px" }}>ROI has been modified since last signature</div>
                    :
                    ""
                    }
            </div>
            <div>
                {roiData == null || roiData.sealingInfo == null ?
                    <>
                        <div style={{ width: "100%", display: "table" }}>
                            <div style={{ color: "lightgray", fontSize: '3rem', fontWeight: 'bold', textAlign: 'center' }}>No ROI Data</div>
                            <div style={{ color: "lightgray", fontSize: '1rem', fontWeight: 'bold', textAlign: 'center' }}>No Sealing Info</div>
                        </div>
                    </>
                    :
                    <>
                        <div style={{ width: "100%", display: "table" }}>
                            <div style={{ width: "12%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%", textAlign: "center" }}>&nbsp;</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}><div style={{ fontWeight: "bold", float: "left", width: "48%" }}>&nbsp;</div><div style={{ float: "left" }}></div></div>
                                    <div style={{ fontWeight: "bold", width: "100%" }}>Signed By:</div>
                                    <div style={{ fontWeight: "bold", width: "100%" }}>Date:</div>
                                    <div style={{ fontWeight: "bold", width: "100%" }}>Review Status:</div>
                                </div>
                            </div>

                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>FIELD COMMISSIONING</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}></div>
                                    <div style={{ width: "100%" }}>{roiData?.inspection?.creatorName}</div>
                                    <div style={{ width: "100%" }}>{roiData?.inspection == null ? <span>&nbsp;</span> : dateFormat(roiData.inspection.completionDate)}</div>
                                    <div style={{ width: "100%" }}>&nbsp;</div>
                                </div>
                            </div>
                            <div style={{ borderLeft: "1px solid lightgrey", float: "left", width: "1px", height: "110px", marginTop: "15px" }}></div>
                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>SENIOR TECH REVIEW</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}>{roleSE04AuthorizedInspector() ? roiSignButton("techReview") : ""}</div>
                                    <div style={{ width: "100%" }}>{roiData?.asset == null || roiData.asset.techReview == null ? <span>&nbsp;</span> : emptyStringFormat(roiData.asset.techReview.updatedBy)}</div>
                                    {roiData?.asset != null && qaStatusDateCheck("techReview") ?
                                        <div style={{ width: "100%" }}>{roiData?.asset == null ? <span>&nbsp;</span> : dateFormat(roiData.asset.techReview.completionDate)}</div>
                                        :
                                        <div style={{ width: "100%", color: "red", fontWeight: "bold" }}>{roiData?.asset == null ? "" : dateFormat(roiData.asset.techReview.completionDate)}</div>
                                    }
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : qaStatusMapping(roiData.asset.techReview.qaStatus)}</div>
                                </div>
                            </div>
                            <div style={{ borderLeft: "1px solid lightgrey", float: "left", width: "1px", height: "110px", marginTop: "15px" }}></div>
                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>QA CONTROLLER REVIEW</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}>
                                        {roiData?.asset != null && qaStatusDateCheck("techReview") && (roleQAManager() || roleQAController()) ? roiSignButton("qaReview") : "" }
                                    </div>
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : emptyStringFormat(roiData.asset.qaReview.updatedBy)}</div>
                                    {roiData?.asset != null && qaStatusDateCheck("qaReview") ?
                                        <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : dateFormat(roiData.asset.qaReview.completionDate)}</div>
                                        :
                                        <div style={{ width: "100%", color: "red", fontWeight: "bold" }}>{roiData?.asset == null ? <span>&nbsp;</span> : dateFormat(roiData.asset.qaReview.completionDate)}</div>
                                    }
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? <span>&nbsp;</span> : qaStatusMapping(roiData.asset.qaReview.qaStatus)}</div>
                                </div>
                            </div>
                            <div style={{ borderLeft: "1px solid lightgrey", float: "left", width: "1px", height: "110px", marginTop: "15px" }}></div>
                            <div style={{ width: "18%", height: "140px", float: "left", padding: "10px" }}>
                                <div style={{ width: "100%" }}>QA MANAGER REVIEW</div>
                                <div style={{ marginTop: "5px", fontSize: "12px" }}>
                                    <div style={{ width: "100%", height: "38px" }}>
                                        {roiData?.asset != null && qaStatusDateCheck("qaReview") && roleSE04CertSigningAuthority() ? roiSignButton("qaApproval") : "" }
                                    </div>
                                    <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : emptyStringFormat(roiData.asset.qaApproval.updatedBy)}</div>
                                    {roiData?.asset != null && qaStatusDateCheck("qaApproval") ?
                                        <div style={{ width: "100%" }}>{roiData?.asset == null ? "" : dateFormat(roiData?.asset.qaApproval.completionDate)}</div>
                                        :
                                        <div style={{ width: "100%", color: "red", fontWeight: "bold" }}>{roiData?.asset == null ? "" : dateFormat(roiData.asset.qaApproval.completionDate)}</div>
                                    }
                                    <div style={{ width: "100%" }}>{qaStatusMapping(roiData?.asset.qaApproval.qaStatus)}</div>
                                </div>
                            </div>
                        </div>

                        <Button type="button" className="btn btn-primary btn-sm" style={{ float: "right", marginRight: "5px", marginTop: "5px" }} onClick={() => roiToPdf()}>ROI TO PDF&nbsp;&nbsp;<i className="fa fa-download" /></Button>

                        <RoiMetering roiData={roiData} />
                </>
                }
            </div>
        </LoadingTemplate>
    );
};

