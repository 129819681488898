import React from "react";
import { useState } from "react";
import { AuthMenu } from "./AuthMenu";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BuildingsPage } from "./Pages/BuildingsPage";

import { BuildingDetailsPage } from "./Pages/BuildingDetailsPage";
import { AssetsPage } from "./Pages/AssetsPage";
import { AssetDetailsPage } from "./Pages/AssetDetailsPage";
import { ProductsPage } from "./Pages/ProductsPage";
import { ProductFamilyPage } from "./Pages/ProductFamilyPage";
import { SearchPage } from "./SearchPage";
import { TemplatesPage } from "./Pages/TemplatesPage";
import { DashboardPage } from "./Pages/DashboardPage";
import { TestPage } from "./Pages/TestPage";
import { DevPage } from "./Pages/DevPage";
import { InspectionPage } from "./Pages/InspectionPage";
import { QAPage } from "./Pages/QAPage";
import { ROIViewPage } from "./Pages/ROIViewPage";
import { LoginPage } from './LoginPage';
import { InspectionPreview } from "./InspectionPreview";
import { NavBar } from './NavBar';
import SearchModal from './SearchModal';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import { azureStorageDomain } from './common/clientConfig'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

//import "./assets/css/template.css"
import './assets/js/template.js';

/*import 'ag-grid-enterprise'

import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-066845}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Quadlogic_Meters_Canada_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MosaiQ}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{MosaiQ}_need_to_be_licensed___{MosaiQ}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_September_2025}____[v3]_[01]_MTc1NzU0NTIwMDAwMA==a3fa70a3a2a9af13e38df02f6d7965e0");
*/
function AppRoutes() {
    return (
        <Routes>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/building/:id" element={<BuildingDetailsPage />} />
            <Route path="/buildings" element={<BuildingsPage />} />
            <Route path="/assets/:buildingId?" element={<AssetsPage />} />
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/productfamily" element={<ProductFamilyPage />} />
            <Route path="/asset/:id?" element={<AssetDetailsPage />} />
            <Route path="/templates" element={<TemplatesPage />} />
            <Route path="/qa/:buildingId?" element={<QAPage />} />
            <Route path="/roiview/:assetId?" element={<ROIViewPage />} />
            <Route path="/test/:buildingId?" element={<TestPage />} />
            <Route path="/dev" element={<DevPage />} />
            <Route path="/inspection/:assetId?/:inspectionId?" element={<InspectionPage />} />
            <Route path="/search/:query" element={<SearchPage />} />
            <Route path="/inspectionpreview/" element={<InspectionPreview />} />
            <Route path="/feedback" element={<FeedBack />} />
            <Route path="*" element={<Navigate to="/buildings" replace />} />
        </Routes>
    )
}

function FeedBack() {
    window.location.href = 'https://qmeters5155.sharepoint.com/:l:/s/ProductsPortal/FA33fhVAaPRMsFIeOyyaM0YB-xzHoPXyA-S-VWCwrO1rYA?nav=ZDlkN2U1YTUtOWRkZS00MWUxLWJmZjktNmQ1NDU5YmZiNTNh';
    return null;
}

export function App() {
    const [isSearchModalOpen, setSearchModalOpen] = useState<boolean>(false);

    return (
        <Router>
            <UnauthenticatedTemplate>
                <LoginPage />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <div>
                    <header id="header" className="container-fluid">
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <img src={azureStorageDomain + "/qmc/mosaiqlogo.png"} id="header-logo" style={{ height: '20px', marginTop: '5px', marginLeft: '10px', float: "left" }} />
                            <div style={{ fontWeight: "bold", color: "yellowgreen", fontSize: "20px", textDecoration: "none" }}>{window.location.hostname === "localhost"? "LOCAL": process.env.NODE_ENV === "development" ? "DEV" : ""}</div>
                        </Link>
                        <span id="menu-open">
                            <i className="fa-solid fa-bars"></i>
                        </span>
                        <span id="header-user">
                            <AuthMenu />
                        </span>
                    </header>
                    <div className="sidebar">
                        <NavBar setSearchModalOpen={setSearchModalOpen} />
                    </div>
                    <div className="main-content">
                        <div className="mx-auto px-4 auto-height" style={{height:"auto!important"}}>
                            <AppRoutes />
                        </div>
                    </div>
                </div>
                <SearchModal
                    isOpen={isSearchModalOpen}
                    onClose={() => setSearchModalOpen(false)}
                />
            </AuthenticatedTemplate>
        </Router>
    );

}
