import React, { useEffect } from 'react'
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import { useAuthFetch } from '../useAuthFetch';
import { getApiUrl } from '../authConfig';

import "../assets/css/modal.css";

import { InspectionObject } from "../common/types";
import { AssetObject } from "../common/types";
import { useParams } from 'react-router-dom';
import { inspectionUpdateResponse, requestInspectionDataLast } from '../hooks/Inspection';
import { assetDataInspectionlatestByAsset } from '../hooks/Asset';


import { createPDFInspection } from '../hooks/CreatePDF';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { azureStorageDomain } from '../common/clientConfig';

export type InspectionPreviewProps = {
    pdfDownload?: string
}

var indent = 0;
var parentId = "";

function formatDuration(duration) {
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + "h " + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + "m " + (secs < 10 ? "0" : "");
    ret += "" + secs + "s";

    return ret;
}

export type RoiProps = {
    assetObject?: AssetObject
}

export function InspectionPage() {
    const authFetch = useAuthFetch();
    const params = useParams();

    const assetId = params.assetId;
    const inspectionId = params.inspectionId;

    const [assetObject, setAssetObject] = useState<AssetObject>(null);
    const [inspectionObject, setInspectionObject] = useState<InspectionObject>(null);

    const [answerEditing, setAnswerEditing] = useState(false);
    const [idEdit, setIdEdit] = useState(null);
    const [answerValue, setAnswerValue] = useState("");

    const requestInitialData = async () => {
        assetDataInspectionlatestByAsset(authFetch, assetId).then(res => { return res; }).then((data) => {
            setAssetObject(data);
            if (inspectionId === undefined) {
                requestInspectionDataLast(authFetch, data.lastInspectionId).then(res => { return res; }).then((rInspectionData) => {
                    setInspectionObject(rInspectionData);
                });
            } else {
                requestInspectionDataLast(authFetch, inspectionId).then(res => { return res; }).then((rInspectionData) => {
                    setInspectionObject(rInspectionData);
                });
            }
        });
    }

    useEffect(() => {
        requestInitialData();
    }, []);

    let assetHeaderImage = "22px";
    let questionIndent = 40;
    let qaContainer = "20px"
    let qaFontSize = "14px";
    let qaMarginTop = "10px";
    let maxAnserImageHeight = "400px";
    let maxAnserImageWidth = "400px";

    const assetTypeImage = (assetType: string) => {
        switch (assetType) {
            case "Thermal":
                return azureStorageDomain + "/qmc/thermal-icon.png";
            case "Gas":
                return azureStorageDomain + "/qmc/gas-icon.png";
            case "Electricity":
                return azureStorageDomain + "/qmc/electricity-icon.png";
            case "Communication":
                return azureStorageDomain + "/qmc/communication-icon.png";
            case "Steam":
                return azureStorageDomain + "/qmc/steam-icon.png";
            default:
                return azureStorageDomain + "/qmc/water-icon.png";
        }
    };

    const checkAnswerColor = (answer: string) => {
        switch (answer) {
            case "N/A":
                return "#bbb";
            case "Complete":
            case "Pass":
                return "#00cc66";
            default:
                return "#3366cc";
        }
    };

    function inspectionToPdf() {
        createPDFInspection(null, [assetObject], assetObject, inspectionObject);
    }

    const openAnswerEdit = async (index: number, answerResponse: string) => {
        if (inspectionObject?.editable == true) {
            //setAnswerValue(answerResponse);
            //setAnswerEditing(true);
            //setIdEdit(index);
        }
    }

    const updateAnswer = async (questionId: string, answer: string) => {
        const data = { answers: [{ questionId: questionId, response: answer }] };

        await inspectionUpdateResponse(authFetch, inspectionObject.id, data).then(res => { return res; }).then((data) => {
            requestInitialData();
        });

        setAnswerEditing(false);
        setIdEdit(null);
    }

    return (
        <div style={{ fontFamily: "Verdana, sans-serif" }}>
            <Button type="button" className="btn btn-primary btn-sm" style={{ float: "right", marginRight: "5px", marginTop: "5px" }} onClick={() => inspectionToPdf()}>INSPECTION TO PDF&nbsp;&nbsp;<i className="fa fa-download" /></Button>

            <div style={{ float: 'left', width: "100%", marginTop: "1%", height: "100px" }}>
                <div style={{ color: "#a39e98", textAlign: "center", fontSize: "36px", fontFamily: "DINPro-Medium, normal" }}>INSPECTION REPORT</div>
            </div>

            <div style={{ overflow: 'auto', padding: "20px 24px" }} >
                <div style={{ color: '#e35205', height: assetHeaderImage }}>
                    <div style={{ float: 'left', fontSize: "22px", height: assetHeaderImage, fontFamily: "DINPro-Medium, normal" }}>
                        <div style={{ height: assetHeaderImage, lineHeight: assetHeaderImage }}>{assetObject == null ? "" : assetObject.manufacturer} - {assetObject == null ? "" : assetObject.model} | {assetObject == null ? "" : assetObject.serialNumber}</div>
                    </div>

                    <div style={{ float: 'right', height: assetHeaderImage, overflow: "hidden" }}>
                        <div style={{ float: 'left', marginRight: "25px", height: assetHeaderImage }}><img style={{ position: "absolute", height: assetHeaderImage }} src={assetObject == null ? "" : assetTypeImage(assetObject.assetType)}></img></div>
                        <div style={{ overflow: "hidden", fontSize: "22px", height: assetHeaderImage, lineHeight: assetHeaderImage, fontFamily: "DINPro-Medium, normal" }}>{assetObject == null ? "" : assetObject.assetType}</div>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>

                <div style={{ backgroundColor: "#e18700", height: "30px", width: "100%", marginTop: "20px" }}>
                    <div style={{ color: "white", marginLeft: "15px", lineHeight: "30px", fontSize: "20px", fontFamily: "DINPro-Regular, normal"  }}>OVERVIEW</div>
                </div>

                <div style={{ border: "solid 1px", borderColor: "#e18700", padding: "10px", float: "left", width: "100%", fontSize: "12px" }}>

                    <div style={{ marginTop: "4px", width: "52%", float: "left" }}>
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>Procedure:</span>{inspectionObject?.template?.name} (v{inspectionObject?.template?.version})<br />
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>Technician:</span>{inspectionObject?.creatorName}<br />
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>Building/Project:</span>{assetObject == null || assetObject.building == null ? "" : assetObject.building.name}<br />
                        <span style={{ float: "left", marginRight: '5px', fontWeight: "bold" }}>State Of Commissioning:</span><span style={{ color: `${checkAnswerColor(assetObject?.status)}` }}>{assetObject == null || assetObject.status == null ? "" : assetObject.status}</span>
                    </div>

                    <div style={{ marginTop: "4px", width: "48%", display: "inline-block" }}>
                        <span style={{ float: 'left', marginRight: '5px', fontWeight: "bold" }}>Completed: </span>{new Date(inspectionObject?.completionDate).toLocaleString()}<br />
                        <span style={{ float: 'left', marginRight: '5px', fontWeight: "bold" }}>Uploaded: </span>{new Date(inspectionObject?.creationDate).toLocaleString()}<br />
                        <span style={{ float: 'left', marginRight: '5px', fontWeight: "bold" }}>Duration: </span>{formatDuration(inspectionObject?.duration)}
                    </div>

                </div>

                <div style={{ backgroundColor: "#e18700", height: "30px", width: "100%", float: 'left', marginTop: "20px" }}>
                    <div style={{ float: "left", color: "white", marginLeft: "15px", lineHeight: "30px", fontSize: "20px", fontFamily: "DINPro-Regular, normal" }}>DETAILS</div>
                    {inspectionObject?.editable == false ?
                        <div style={{ marginRight: "5px", color: "white", lineHeight: "30px", float: "right" }}>
                            <img src={azureStorageDomain + "/qmc/lock-icon.png"} height="20px" style={{ paddingRight: "5px" }} />Editing Locked
                        </div>
                        :
                        ""
                    }
                </div>

                {
                    inspectionObject?.answers?.map((answer, idx) =>
                        <>
                            {(() => {
                                if (answer.parentQuestionId == "") {
                                    parentId = "";
                                    indent = 0;
                                    return (

                                        <div style={{ width: "100%", float: "left", marginTop: idx === 0 ? '-10px' : "5px" }} key={"anscont-" + idx}>
                                            {idx != 0 ? <div id={`qa-${idx}`} key={"x-" + idx} style={{ width: "100%", float: "left", marginTop: '-2px', borderBottom: "1px solid #e18700" }} /> : <div id={`qa-${idx}`} style={{ width: "100%", float: "left", marginTop: "-2px" }} key={idx}>&nbsp;</div>}
                                            <div key={"ans-" + idx} style={{ float: "left", width: "100%", marginTop: qaMarginTop, marginLeft: indent } as React.CSSProperties}>
                                                <div style={{ height: qaContainer, display: "table" }}>
                                                    <span style={{ fontSize: qaFontSize, float: "left", marginRight: "5px", color: "#000000", fontWeight: "bold" }}>{answer.questionText == null || answer.questionText == "" ? answer.questionId : answer.questionText}</span>
                                                    {answerEditing ? (
                                                        <div style={{ float: "left", display: "flex" }}>
                                                            <Form.Control className="modal-input" size="sm" style={{ float: "left", marginRight: "5px", display: idEdit == idx ? '' : 'none' }} placeholder={answer.response} type="text" value={answerValue} onChange={e => setAnswerValue(e.target.value )}  />
                                                            <Button className="btn btn-primary btn-sm" style={{ float: "left", marginRight: "5px", display: idEdit == idx ? 'block' : 'none' }} onClick={() => { updateAnswer(answer.questionId, answerValue); }}>Update</Button>
                                                            <Button className="btn btn-danger btn-sm" style={{ float: "left", display: idEdit == idx ? 'block' : 'none' }} onClick={() => { setAnswerEditing(false); setIdEdit(null); }}>Cancel</Button>
                                                        </div>
                                                    ) : (
                                                            <>
                                                                <span onClick={() => { openAnswerEdit(idx, answer.response); }} style={{ cursor: inspectionObject?.editable == true ? "pointer" : "", fontSize: qaFontSize, padding: "4px 10px 4px 10px", color: "#6c6a6a" }}>
                                                                    {answer.response}
                                                                    {answer.history && answer.history.length > 0 ? <span style={{ fontSize: "9px" }}> ({moment(answer.history[0].creationDate).format('yyyy-MM-DD')} by {answer.history[0].creatorName})</span> : ""}
                                                                </span>
                                                                {answer.history && answer.history.length > 0 ?
                                                                    <div style={{ display: answer.history && answer.history.length > 0 ? '' : 'none', color: "red", fontSize: qaFontSize, padding: "4px 10px 4px 10px" }}>Previous Response: {answer.history[1].response}<span style={{ fontSize: "9px" }}> ({moment(answer.history[1].creationDate).format('yyyy-MM-DD')} by {answer.history[1].creatorName})</span></div>
                                                                    : ""
                                                                }
                                                        </>

                                                    )}
                                                </div>
                                                {answer.photoContent != null ? answer.photoContent.map((photo, pidx) => {
                                                    return <><img id={`img-${photo.photoId}`} key={"photo-" + pidx} className="mb-2 me-2" style={{ maxHeight: maxAnserImageHeight, maxWidth: maxAnserImageWidth }} src={"data:" + photo.mimeType + ";base64," + photo.base64Content} /></>
                                                }) : null
                                                }
                                            </div>
                                        </div>
                                    )
                                } else {
                                    if (answer.parentQuestionId != parentId) { indent = indent + questionIndent; }

                                    parentId = answer.parentQuestionId;
                                    return (
                                        <div key={"anscont-" + idx} style={{ float: "left", width: "100%", marginTop: qaMarginTop, marginLeft: indent } as React.CSSProperties}>
                                            <div style={{ height: qaContainer, display: "table" }}>
                                                <span style={{ fontSize: qaFontSize, float: "left", marginRight: "5px", color: "#000000", fontWeight: "bold" }}>{answer.questionText == null || answer.questionText == "" ? answer.questionId : answer.questionText}</span>
                                                {answerEditing ? (
                                                    <div style={{ float: "left", display: "flex" }}>
                                                        <Form.Control className="modal-input" size="sm" style={{ float: "left", marginRight: "5px", display: idEdit == idx ? '' : 'none' }} placeholder={answer.response} type="text" value={answerValue} onChange={e => setAnswerValue(e.target.value)} />
                                                        <Button className="btn btn-primary btn-sm" style={{ float: "left", marginRight: "5px", display: idEdit == idx ? 'block' : 'none' }} onClick={() => { updateAnswer(answer.questionId, answerValue); }}>Update</Button>
                                                        <Button className="btn btn-danger btn-sm" style={{ float: "left", display: idEdit == idx ? 'block' : 'none' }} onClick={() => { setAnswerEditing(false); setIdEdit(null); }}>Cancel</Button>
                                                    </div>
                                                ) : (
                                                    <>
                                                            <span onClick={() => { openAnswerEdit(idx, answer.response); }} style={{ cursor: inspectionObject?.editable == true ? "pointer" : "", fontSize: qaFontSize, padding: "4px 10px 4px 10px", color: "#6c6a6a" }}>
                                                                {answer.response}
                                                                {answer.history && answer.history.length > 0 ? <span style={{ fontSize: "9px" }}> ({moment(answer.history[0].creationDate).format('yyyy-MM-DD')} by {answer.history[0].creatorName})</span> : ""}
                                                            </span>
                                                            {answer.history && answer.history.length > 0 ?
                                                                <div style={{ display: answer.history && answer.history.length > 0 ? '' : 'none', color: "red", fontSize: qaFontSize, padding: "4px 10px 4px 10px" }}>Previous Response: {answer.history[1].response}<span style={{ fontSize: "9px" }}> ({moment(answer.history[1].creationDate).format('yyyy-MM-DD')} by {answer.history[1].creatorName})</span></div>
                                                                : ""
                                                            }
                                                    </>
                                                )}
                                            </div>
                                            {answer.photoContent != null ? answer.photoContent.map((photo, pidx) => {
                                                return <><img id={`img-${photo.photoId}`} key={"photo-" + pidx} className="mb-2 me-2" style={{ maxHeight: maxAnserImageHeight, maxWidth: maxAnserImageWidth }} src={"data:" + photo.mimeType + ";base64," + photo.base64Content} /></>
                                            }) : null
                                            }
                                        </div>
                                    )

                                }
                            })()}
                        </>
                    )}

            </div>
        </div>
    );
};