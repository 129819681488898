import { useState } from "react";
import { useAuthFetch } from '.././useAuthFetch'
import { getApiUrl } from '.././authConfig';

export async function requestAssetData (authFetch: any, buildingId: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request Asset Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function assetDataInspectionlatestByAsset(authFetch: any, assetId: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`assets/${assetId}?building=true&latestInspection=true`))
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData;
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request Asset Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function assetDataInspectionlatestByBuilding(authFetch: any, buildingId: any) {
    let fetchResponse = await authFetch('GET', getApiUrl(`assets/building/${buildingId}?building=true&latestInspection=true`));
    if ((fetchResponse.status === 200 || fetchResponse.status === 201)) {
        let responseData = [];
        try {
            responseData = await fetchResponse.json();
        } catch (error) {
            console.log('Request Asset Data Error: ' + error);
        } finally {
            return responseData;
        }
    }
}

export async function assetDataMoveBuilding(authFetch: any, url: any, assetData: any, assetBuildingMove: string) {
    let fetchResponse = await authFetch("PUT", url, { ...assetData, buildingId: assetBuildingMove })
    if (fetchResponse.status != 409) {
        let responseData = [];
        if (fetchResponse.status != 409) {
            return responseData = await fetchResponse.json();
        } else {
            return "ERROR";
        }
    }
}